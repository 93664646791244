import Base = require("Everlaw/Base");
import Cmp = require("Everlaw/Core/Cmp");

class FormatType extends Base.Object {
    get className() {
        return "FormatType";
    }
    // the name of the doctype (not a number)
    override id: string;
    name: string;
    constructor(id: string, name?: string) {
        super({ id });
        this.name = name ? name : id;
    }
    override display() {
        return this.name;
    }
    override compare(other: FormatType) {
        return Cmp.full(this.id, other.id);
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module FormatType {
    export function byString(str: string) {
        return Base.get(FormatType, str);
    }

    // ImageOrPdf is a pseudo-format that does not exist on the back-end.
    // In the Has Format search term, it gets changed to a compound Eql.
    // The id ensures that it appears first in the Has Format drop-down menu.
    export const ImageOrPdf = new FormatType("1st", "Image or PDF");

    export const Image = new FormatType("Image");
    export const Pdf = new FormatType("Pdf", "PDF");
    export const Native = new FormatType("Native");
    export const Text = new FormatType("Text");

    export const all = [ImageOrPdf, Image, Pdf, Native, Text];
    all.forEach(Base.add);
}

export = FormatType;
