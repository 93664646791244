import Base = require("Everlaw/Base");
import { SftpDialog } from "Everlaw/SftpDialog";
import { checkForMalware } from "Everlaw/MalwareWarningDialog";

class ProductionJob extends Base.Object {
    get className() {
        return "ProductionJob";
    }

    override id: number;
    productionId: number;
    active: boolean;
    userId: number;
    created: number;
    config: {};
    numDocs: number;
    name: string;
    description: string;

    pageCount: number;
    queuedCount: number;
    processedCount: number;
    remainingErrorCount: number;
    deletedCount: number;

    constructor(params: any) {
        super(params);
        this._mixin(params);
    }

    override _mixin(params: any) {
        Object.assign(this, params);
    }

    createSFTPDialog() {
        let eql;
        import("Everlaw/Property").then((Property) => {
            eql = new Property.ModifiedBy(
                Base.get(ProductionJob, this.id),
                "productionJob",
            ).toString();
            checkForMalware({
                eql,
                onInclude: () => {
                    new SftpDialog({
                        gaName: this.className,
                        usernameUrl: "production/getJobSftpUsername.rest",
                        credentialsUrl: "production/setJobSftpCredentials.rest",
                        content: { id: this.id },
                    });
                },
            });
        });
    }
}

export { ProductionJob };
