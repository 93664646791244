import ActionNode = require("Everlaw/UI/ActionNode");
import Arr = require("Everlaw/Core/Arr");
import { ColorTokens, EverColor } from "design-system";
import { Constants } from "Everlaw/Constants";
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Files = require("Everlaw/Files");
import Icon = require("Everlaw/UI/Icon");
import * as Input from "Everlaw/Input";
import * as Rest from "Everlaw/Rest";
import Str = require("Everlaw/Core/Str");
import UI = require("Everlaw/UI");
import Util = require("Everlaw/Util");
import event = require("dojo/_base/event");
import on = require("dojo/on");
import { makeFocusable } from "Everlaw/UI/FocusDiv";
import { MAX_FILES } from "Everlaw/FilePicker";

/**
 * An overlay that covers the entire page, serving as a drag-and-drop target for `Files.Entry`s.
 * Users should be careful that only one of these are visible at any given time; in most cases,
 * there should only be one overlay per page. That said, it is possible to attach the Overlay to a
 * specific node, so multiple Overlays can exist on the same page as long as all but one are hidden.
 */
export class Overlay {
    /**
     * CSS settings cause this table to cover the entire page, but it's only visible while the user
     * is dragging a folder and there is an intended target for that folder.
     */
    node = Dom.table({ class: "files-dnd-overlay hidden" });

    private root = Dom.place(Dom.tbody(), this.node);

    private destroyables: Util.Destroyable[] = [
        // When the user drags a folder anywhere on the body, we display the node, but only if it
        // has registered DND targets. Thereafter, drag events will be intercepted by the registered
        // cells, and these events will no longer apply.
        on(
            document.body,
            "dragover",
            stopping((evt: DragEvent) => {
                // Files and folders have type "Files"; dragging, e.g., the header image still triggers
                // a dragover event, but with a non-Files type.
                if (this.node.rows.length && Arr.contains(evt.dataTransfer.types, "Files")) {
                    Dom.show(this.node);
                }
            }),
        ),
        // The dragaway event is usually handled by the registered targets, but if the user drags a
        // folder while the overlay is hidden (e.g., while not on the Uploads tab), document.body
        // will still be the recipient of dragaway.
        on(document.body, "dragaway", () => {
            Dom.hide(this.node);
        }),
    ];

    private deregister: () => void;

    /**
     * Activates the overlay so that it will be displayed while users are dragging files or folders
     * over the page. After the user drops files or folders, or after calling deactivate(), dragging
     * files or folders over the page will do nothing.
     */
    activate(
        // Displayed in the center of the overlay as "Drop ${dropWhat}" when the user is
        // hovering over the viewport with files or folders.
        dropWhat: string,
        // Called when the user drops files or folders. This method may optionally return false
        // to prevent automatic deactivate(). This is useful in conjunction with Dialog.ok(...) to
        // indicate that the user did not drop valid items.
        onDrop: (entries: Files.Entry[]) => boolean | void,
        // When the overlay is deactivated either by a successful drop or by a call to
        // deactivate, this callback executes.
        onDeactivate?: () => void,
    ) {
        this.deactivate();
        return (this.deregister = this.register(dropWhat, onDrop, onDeactivate));
    }

    /**
     * Ends the overlay, so that it will not even be displayed when the user is dragging files or
     * folders over it.
     */
    deactivate() {
        if (this.deregister) {
            const deregLocal = this.deregister;
            // Clear the deregister callback before we call it, so that we don't end up looping if
            // it triggers a deregistration.
            this.deregister = null;
            deregLocal();
        }
    }

    getNode() {
        return this.node;
    }

    private register(
        // Displayed in the center of elt's overlay section as "Drag ${dragWhat}" when the user
        // is hovering over the viewport with files or folders, and "Drop ${dragWhat}" when the
        // user is hovering over elt's section.
        dragWhat: string,
        // Called when the user drops files or folders. This method may optionally return false
        // to prevent the automatic deregister() of elt. This is useful in conjunction with
        // Dialog.ok(...) to indicate that the user did not drop valid items.
        onDrop: (entries: Files.Entry[]) => boolean | void,
        // This callback executes when this element's overlay section is deregistered, either by
        // a successful drop or by calling the returned deregister function.
        onDeregister?: () => void,
    ) {
        const dndRegion = this.root.insertRow(0);
        const dnd = Dom.place(Dom.td({ class: "files-dnd-region" }, "Drag ", dragWhat), dndRegion);
        let connects = undefined;
        const deregister = (this.deregister = () => {
            Util.destroy(connects);
            Dom.destroy(dndRegion);
            onDeregister && onDeregister();
        });
        connects = connect(dnd, {
            onDragOver() {
                Dom.setContent(dnd, "Drop ", dragWhat);
                Dom.addClass(dnd, "hovering");
            },
            onDragAway: () => {
                Dom.setContent(dnd, "Drag ", dragWhat);
                Dom.removeClass(dnd, "hovering");
                Dom.hide(this.node);
            },
            onDrop: (entries) => {
                if (onDrop(entries.filter((e) => e.isFile || e.isDirectory)) !== false) {
                    deregister();
                }
                Dom.hide(this.node);
            },
        });

        return this.deregister;
    }

    destroy() {
        Dom.remove(this.node);
        this.deactivate();
        Util.destroy(this.destroyables);
    }
}

export interface RootableOverlayParams {
    overlayContent: HTMLElement /* Content that is shown in the overlay. */;
    onDrag?: () => void /* Called when files are dragged over a target. */;
    onDrop: (files: Files.Entry[]) => void /* Called when user drops files onto a target. */;
    dragTargets?: HTMLElement[] /* The initial drag targets. More can be added later with addTarget. */;
}

/**
 * A customizable overlay intended to only cover part of the screen, with the
 * ability to set custom targets that will trigger the overlay.
 *
 * To use, ensure you call addTarget on an element (usually the parent node)
 * or pass in the desired targets in the dragTargets parameter.
 */
export class RootableOverlay {
    node: HTMLDivElement;
    destroyables: Util.Destroyable[] = [];
    showingOverlay = false;
    draggingOverOverlay = false;
    onDrag?: () => void;
    onDrop: (files: Files.Entry[]) => void;

    constructor(params: RootableOverlayParams) {
        this.node = Dom.div({ class: "files-dnd-rootable-overlay" }, params.overlayContent);
        this.onDrag = params.onDrag;
        this.onDrop = params.onDrop;
        Dom.hide(this.node);

        connect(params.overlayContent, {
            onDragOver: () => {
                this.draggingOverOverlay = true;
            },
            onDragAway: () => {
                /*
                 * Can't just do this.draggingOverOverlay = false, because
                 * then the overlay will linger if the user drags directly
                 * from the overlay to another window.
                 */
                this.endDrag();
            },
            onDrop: (entries) => {
                this.endDrag();
                params.onDrop(entries);
            },
        });

        params.dragTargets?.forEach((target) => this.addTarget(target));
    }

    /**
     * Add a target that will activate the overlay when a dragging event occurs
     * over the target.
     */
    addTarget(target: HTMLElement): void {
        this.destroyables.push(
            connect(target, {
                onDragOver: () => {
                    this.draggingOverOverlay = false;
                    this.onDrag?.();
                    Dom.show(this.node);
                    this.showingOverlay = true;
                },
                onDragAway: () => {
                    if (!this.draggingOverOverlay) {
                        this.endDrag();
                    }
                },
                onDrop: (entries) => {
                    this.endDrag();
                    this.onDrop(entries);
                },
            }),
        );
    }

    private endDrag() {
        this.showingOverlay = false;
        this.draggingOverOverlay = false;
        Dom.hide(this.node);
    }

    destroy() {
        Dom.remove(this.node);
        Util.destroy(this.destroyables);
    }
}

/**
 * Accepts a node and connects the necessary events to it so that it can accept the drag-and-drop of
 * files and folders. The given params provides callbacks that fire for the various drag events.
 *
 * @param nodeOrId  either a DOM node or a DOM node ID
 * @param params
 *  @param.onDragAway   fires when files or folders are dragged away from the given node
 *  @param.onDragOver   fires multiple times as the files or folders are dragged over the node
 *  @param.onDrop       fires when the files or folders are dropped on the node, with the list of
 *      file and directory entries as the argument
 * @param context   the context in which to perform the callbacks (optional)
 */
export function connect(
    node: HTMLElement,
    params: {
        onDrop: (entries: Files.Entry[]) => void;
        onDragAway?: () => void;
        onDragOver?: () => void;
    },
) {
    // Attach the drag events -- dragenter with preventDefault is necessary for the drop event
    // to actually fire.
    return [
        on(node, "dragenter", stopping(params.onDragOver)),
        on(node, "dragover", stopping(params.onDragOver)),
        on(node, "dragleave", stopping(params.onDragAway)),
        on(
            node,
            "drop",
            stopping((evt) => {
                const transfer = evt.dataTransfer;
                /*
                 * Currently, only Chrome has transfer.items, and thus is the only browser
                 * that can handle dropping folders.
                 *
                 * Files will be marked as directories in non-Chrome browsers if they
                 * are unable to be read in the browser. These FileEntries won't have
                 * the fullPath.
                 */
                if (transfer.items) {
                    params.onDrop(droppedItemsToEntries(transfer.items));
                } else if (transfer.files) {
                    droppedFilesToEntries(transfer.files, params.onDrop);
                }
            }),
        ),
    ];
}

/// connect Helpers

/**
 * Accepts a callback. Returns a function that will stop an event argument and then call the
 * callback in the given context with the event as its argument.
 */
function stopping(callback?: (ev: DragEvent) => void): (ev: DragEvent) => void {
    return (ev) => {
        event.stop(ev);
        callback && callback(ev);
    };
}

function droppedItemsToEntries(items: DataTransferItemList) {
    return Arr.fromArrayLike<DataTransferItem>(items)
        .map((item) => getDataTransferItemAsEntry(item))
        .filter((entry) => entry !== null);
}

export function droppedFilesToEntries(fileList: FileList, cb: (e: Files.Entry[]) => void) {
    if (fileList.length === 0) {
        cb([]);
        return;
    }
    const files = Arr.fromArrayLike<File>(fileList);
    const isFile: boolean[] = [];
    let joined = 0;
    const join = (i: number, res: boolean) => {
        isFile[i] = res;
        if (++joined === files.length) {
            cb(
                files.map((f, i) => ({
                    isFile: isFile[i],
                    isDirectory: !isFile[i],
                    name: f.name,
                    fullPath: f["webkitRelativePath"] || null,
                    file: (g) => g(f),
                    getMetadata: (c) =>
                        c({ size: f.size, modificationTime: new Date(f.lastModified) }),
                })),
            );
        }
    };
    files.forEach((file, i) => {
        /*
         * Here, we test the file for whether it is a file or directory.
         * The naive solution is to readAsArrayBuffer(file.slice(0,1)) to
         * minimize memory usage, but slicing an empty file has strange behavior
         * across OSs. Thus, we assume files larger than 1MB are not directories,
         * and try to read all others. This can be problematic if the user
         * drops a large amount of small files, but will still resolve fairly quickly
         * because of the async readers.
         */
        if (file.size > Constants.MB) {
            join(i, true);
        } else {
            const reader = new FileReader();
            reader.onload = () => join(i, true);
            reader.onerror = () => join(i, false);
            reader.readAsArrayBuffer(file);
        }
    });
}

export function inputElementFilesToEntries(fileList: FileList, cb: (e: Files.Entry[]) => void) {
    if (fileList.length === 0) {
        cb([]);
        return;
    }
    const files = Arr.fromArrayLike<File>(fileList);
    const entries: Files.Entry[] = [];
    for (const file of files) {
        const fileEntry = {
            isFile: true,
            isDirectory: false,
            name: file.name,
            fullPath: file["webkitRelativePath"] || null,
            file: (g) => g(file),
            getMetadata: (c) =>
                c({ size: file.size, modificationTime: new Date(file.lastModified) }),
        };
        entries.push(fileEntry);
    }
    cb(entries);
}

/**
 * The drag-and-drop event's method name used to access the FileEntry API from a dropped file or
 * folder. It cannot be calculated until the first drop event, but thereafter we would like to
 * avoid recalculating it.
 */
let GET_AS_ENTRY: string;

function getDataTransferItemAsEntry(dtItem: any): Files.Entry {
    if (!GET_AS_ENTRY) {
        if (dtItem["webkitGetAsEntry"]) {
            GET_AS_ENTRY = "webkitGetAsEntry";
        } else if (dtItem["getAsEntry"]) {
            GET_AS_ENTRY = "getAsEntry";
        } else {
            Dialog.ok("No suitable method", "This browser does not support the necessary API.");
            return null;
        }
    }
    return dtItem[GET_AS_ENTRY].call(dtItem);
}

/**
 * Verify that a single file has been specified. If so, resolve the promise. If not, rejects it.
 */
export function withSingleFile(entries: Files.Entry[]): Promise<File> {
    return new Promise<File>((resolve, reject) => {
        if (entries.length !== 1 || !entries[0].isFile) {
            throw new Error("Please specify 1 valid file");
        }
        (<Files.FileEntry>entries[0]).file(resolve, reject);
    });
}

/**
 * Verify that multiple files within a number limit have been specified.
 * If so, resolve the promise. If not, rejects it.
 */
function withMultipleFile(entries: Files.Entry[], maxFileNum: number): Promise<File[]> {
    if (
        entries.length > 0
        && entries.length <= maxFileNum
        && entries.every((entry) => entry.isFile)
    ) {
        const fileEntries = entries.map((entry) => <Files.FileEntry>entry);
        return Files.load(fileEntries);
    } else {
        return Promise.reject();
    }
}

interface MultipleFilesInputFormParams extends FileInputFormParams {
    onFilesChange?: (files: File[]) => void;
    maxFileNum?: number;
}

interface FileInputFormParams {
    validExtensions?: string[];
    checkEmptyFile?: boolean;
    maxFileSize?: number;
    styleClass?: string;
    label?: Dom.Content;
    labelSecondary?: Dom.Content;
    footer?: Dom.Content;
    // maximum number of characters shown when displaying file name (anything after will be replaced with "...")
    maxFilenameLengthInDisplay?: number;
}

/**
 * A widget for specifying multiple files for upload, either via drag-and-drop or via
 * traditional OS file browsing. The widget validates that a certain number of files are selected,
 * and optionally whether that file's extensions matches a list of valid extensions.
 * Unlike the SingleFileUploadForm below, this widget does not allow direct upload.
 * If we want to upload multiple files at the same time, we should consider using MultipartUpload
 * rather than uploading files in a single REST request as in SingleFileUploadForm.
 */
export class MultipleFilesInputForm {
    node: HTMLElement;
    protected currentFiles: Set<File>;
    private validExtensions: string[];
    private extensionString: string;
    private onFilesChange: (files: File[]) => void;
    private maxFileNum: number = MAX_FILES;
    private maxFileSize: number;
    private checkEmptyFile = true;
    private labelNode: HTMLElement;
    private fileInput: HTMLInputElement;
    selectionNode: HTMLElement;
    selectedFilesNode: HTMLElement;
    private errorMsgNode: HTMLElement;
    private errorMsgTextNode: HTMLElement;
    private footerNode: HTMLElement;
    protected toDestroy: Util.Destroyable[] = [];
    private maxFilenameLengthInDisplay: number;
    constructor(params: MultipleFilesInputFormParams) {
        Object.assign(this, params);

        if (params.label || params.labelSecondary) {
            this.labelNode = Dom.div(
                { class: "label-node" },
                params.label ? Dom.span({ class: "h7" }, params.label) : null,
                params.labelSecondary ? Dom.span(params.labelSecondary) : null,
            );
        }

        if (this.validExtensions && this.validExtensions.length) {
            this.extensionString =
                Arr.slice(this.validExtensions, 0, this.validExtensions.length - 1).join(", ")
                + (this.validExtensions.length > 1 ? " or " : "")
                + this.validExtensions[this.validExtensions.length - 1];
        }

        // Build the content for the selection (drag-and-drop) node.
        const browseText = Dom.span({ style: { color: ColorTokens.TEXT_LINK } }, "browse");
        const selectionNodeContent = Dom.div(
            Dom.div(
                `Drag and drop your ${Str.pluralForm("file", this.maxFileNum)} here or `,
                browseText,
            ),
        );
        if (this.extensionString) {
            Dom.place(
                Dom.div({ class: "extension-string" }, "(" + this.extensionString + ")"),
                selectionNodeContent,
            );
        }
        this.selectionNode = UI.alignedContainer({
            cssClass: "selection-node",
            vertical: true,
            content: selectionNodeContent,
        });
        const browseFocusDiv = makeFocusable(browseText, "focus-text-style");
        this.toDestroy.push(
            new ActionNode(this.selectionNode, {
                onClick: () => this.selectFiles(),
            }),
            browseFocusDiv,
            Input.fireCallbackOnKey(browseFocusDiv.node, [Input.ENTER], () => this.selectFiles()),
        );
        connect(this.selectionNode, {
            onDrop: (entries: Files.Entry[]) => {
                this.setSelectionDragOver(false);
                this.loadFiles(entries);
            },
            onDragAway: () => this.setSelectionDragOver(false),
            onDragOver: () => this.setSelectionDragOver(true),
        });

        // Build the error message node.
        this.errorMsgNode = Dom.div(
            { class: "error-msg-node hidden" },
            Dom.node(new Icon("alert-triangle-red-20")),
            (this.errorMsgTextNode = Dom.div()),
        );

        params.footer && (this.footerNode = Dom.div({ class: "footer-node" }, params.footer));

        this.selectedFilesNode = Dom.div({ class: "hidden v-spaced-8" });

        this.node = Dom.div(
            { class: ["file-input-form", params.styleClass].join(" ").trim() },
            this.labelNode,
            this.selectionNode,
            this.selectedFilesNode,
            this.errorMsgNode,
            this.footerNode,
        );

        this.fileInput = Dom.input({
            class: "hidden",
            type: "file",
            multiple: this.maxFileNum > 1,
            accept: this.extensionString ? this.validExtensions.join(",") : null,
        });
        this.toDestroy.push(
            on(this.fileInput, "change", () => {
                if (!this.fileInput.files) {
                    this.reset();
                } else {
                    droppedFilesToEntries(this.fileInput.files, (entries: Files.Entry[]) =>
                        this.loadFiles(entries),
                    );
                }
            }),
        );
    }

    private setSelectionDragOver(draggingOver: boolean): void {
        Dom.toggleClass(this.selectionNode, "dragging-over", draggingOver);
    }

    selectFiles(): void {
        this.fileInput.value = "";
        this.fileInput.click();
    }

    private loadFiles(entries: Files.Entry[]): void {
        if (entries.length === 0) {
            this.reset();
            return;
        }

        this.verifyFiles(entries);
    }

    private verifyFiles(entries: Files.Entry[]): void {
        // For any file upload with MultipleFilesInputForm, we should consider adding the checks below to
        // backend upload endpoints as well.
        if (
            this.extensionString
            // If any entry has an invalid extension, we will set error.
            && entries.some(
                (entry) => !this.validExtensions.some((ext) => Str.endsWith(entry.name, ext, true)),
            )
        ) {
            this.setError("Filetype must be " + this.extensionString);
        } else {
            withMultipleFile(entries, this.maxFileNum)
                .then((files) => {
                    if (this.checkEmptyFile && files.some((file) => file.size === 0)) {
                        this.setError("Empty file detected in selected files");
                    } else if (
                        this.maxFileSize
                        && files.some((file) => file.size > this.maxFileSize)
                    ) {
                        this.setError("Oversized file detected in selected files");
                    } else {
                        this.setFiles(files);
                    }
                })
                .catch(() =>
                    this.setError(
                        `Please specify ${Str.pluralForm("valid file", this.maxFileNum)} `
                            + `(At most ${this.maxFileNum} ${Str.pluralForm("file", this.maxFileNum)})`,
                    ),
                );
        }
    }

    setError(error: string): void {
        const hasError = !!error;
        hasError ? Dom.setContent(this.errorMsgTextNode, error) : Dom.empty(this.errorMsgTextNode);
        Dom.show(this.errorMsgNode, hasError);
    }

    setSelectedFilesDisplay(): void {
        this.hasSelectedFiles()
            ? this.populateSelectedFilesDisplay()
            : this.resetSelectedFilesDisplay();
    }

    populateSelectedFilesDisplay(): void {
        Dom.setContent(
            this.selectedFilesNode,
            this.getSelectedFiles().map((file) => {
                let filename = file.name;
                if (this.maxFilenameLengthInDisplay) {
                    filename = Str.ellipsify(filename, this.maxFilenameLengthInDisplay);
                }
                const selectedFile = Dom.div(
                    { class: "selected-file" },
                    Dom.div({ class: "selected-file-name" }, filename),
                );
                this.toDestroy.push(
                    new Icon.ActionIcon("x-red", {
                        alt: "switch file",
                        parent: selectedFile,
                        onClick: () => {
                            this.currentFiles.delete(file);
                            Dom.destroy(selectedFile);
                            this.currentFiles.size === 0 && this.reset();
                        },
                        makeFocusable: true,
                        focusStyling: "focus-text-style",
                    }),
                );
                return selectedFile;
            }),
        );
        Dom.show(this.selectedFilesNode);
    }

    resetSelectedFilesDisplay(): void {
        Dom.empty(this.selectedFilesNode);
        Dom.hide(this.selectedFilesNode);
    }

    private setFiles(files: File[]): void {
        const validFiles = !!files;

        this.currentFiles = validFiles ? Arr.toSet(files) : null;
        this.onFilesChange && this.onFilesChange(this.getSelectedFiles());

        Dom.hide(this.selectionNode, validFiles);
        this.footerNode && Dom.hide(this.footerNode, validFiles);
        validFiles && this.setError(null);
        this.setSelectedFilesDisplay();
    }

    getSelectedFiles(): File[] {
        return this.hasSelectedFiles() ? Array.from(this.currentFiles) : null;
    }

    hasSelectedFiles(): boolean {
        return !!this.currentFiles && this.currentFiles.size !== 0;
    }

    /** Reset the widget. Does not clear any error state. */
    reset(): void {
        this.setFiles(null);
    }

    destroy(): void {
        Util.destroy(this.toDestroy);
        Dom.destroy(this.node);
    }
}

/**
 * Params interface used by SingleFileUploadForm below.
 */
interface SingleFileUploadFormParams extends FileInputFormParams, UploadFileParams {
    fileFormName: string;
    onFileChange?: (file: File) => void;
    uploadUponDrop?: boolean;
    throwUploadError?: boolean;
}

export interface UploadFileParams {
    uploadEndPoint?: string;
    uploadParams?: { [paramName: string]: unknown };
    customOnUploadStart?: () => void;
    customOnUploadSuccess?: () => void;
    customOnUploadFailure?: (err: Rest.Failed) => void;
    customOnUploadFinally?: () => void;
}

/**
 * A widget used for specifying a single file for upload, either via drag-and-drop or via
 * traditional OS file browsing. The widget validates that a single file is selected, and optionally
 * whether that file's extensions matches a list of valid extensions. If the onFileChange() callback
 * doesn't immediately start the upload process by calling onUploadStart(), the widget
 * displays the selected file in a deletable div. Deleting the selected file also triggers a
 * callback to onFileChange().
 */
export class SingleFileUploadForm {
    private fileFormName: string;
    private uploadUponDrop: boolean;
    private throwUploadError?: boolean;
    private uploadEndPoint?: string;
    private uploadParams?: { [paramName: string]: unknown };
    private customOnUploadStart?: () => void;
    private customOnUploadSuccess?: () => void;
    private customOnUploadFailure?: (err: Rest.Failed) => void;
    private customOnUploadFinally?: () => void;
    private fileInputForm: MultipleFilesInputForm;
    private onUploadNode: HTMLElement;
    private onUploadNodeFileText: HTMLElement;
    private onUploadNodeStatusText: HTMLElement;
    private resetNode: HTMLElement;
    protected toDestroy: Util.Destroyable[] = [];
    constructor(params: SingleFileUploadFormParams) {
        Object.assign(this, params);

        this.toDestroy.push(
            (this.fileInputForm = new MultipleFilesInputForm(
                Object.assign(params, {
                    onFilesChange: params.onFileChange
                        ? (files: File[]) => params.onFileChange(files ? files[0] : null)
                        : null,
                    maxFileNum: 1,
                }),
            )),
        );

        // We override this.fileInputForm.setSelectedFilesDisplay since we want to give users
        // the option to upload file upon select: this.uploadUponDrop.
        // Besides, since we allow users to directly upload file through this.uploadFile,
        // we need to reset the onUploadNode when we are resetting the display through
        // this.fileInputForm.resetSelectedFilesDisplay.
        this.fileInputForm.setSelectedFilesDisplay = () => {
            if (this.uploadUponDrop && this.hasSelectedFile()) {
                this.uploadFile();
            } else if (this.hasSelectedFile()) {
                this.fileInputForm.populateSelectedFilesDisplay();
            } else {
                this.fileInputForm.resetSelectedFilesDisplay();
                this.onUploadNode && Dom.hide(this.onUploadNode);
            }
        };

        this.resetNode = Dom.div({
            class: "hidden",
            style: { color: EverColor.EVERBLUE_40 },
        });
        this.toDestroy.push(
            new ActionNode(this.resetNode, {
                onClick: () => {
                    this.reset();
                    Dom.hide(this.resetNode);
                },
            }),
        );

        const onUploadContent = Dom.div(
            (this.onUploadNodeFileText = Dom.div({ class: "upload-file" })),
            (this.onUploadNodeStatusText = Dom.div({ class: "uploading-msg" })),
            this.resetNode,
        );
        Dom.place(
            (this.onUploadNode = UI.alignedContainer({
                cssClass: "on-upload-node hidden",
                vertical: true,
                content: onUploadContent,
            })),
            this.fileInputForm.selectionNode,
            "after",
        );
    }

    uploadFile(params: UploadFileParams = {}): void {
        if (this.getUploadEndpoint(params) && this.hasSelectedFile()) {
            this.onUploadStart();
            const customOnUploadStart = this.getCustomOnUploadStart(params);
            customOnUploadStart && customOnUploadStart();

            Rest.uploadFile(
                this.getUploadEndpoint(params),
                this.getFileFormData(),
                this.getUploadParams(params),
            )
                .then(
                    () => {
                        this.onUploadSuccess();
                        const customOnUploadSuccess = this.getCustomOnUploadSuccess(params);
                        customOnUploadSuccess && customOnUploadSuccess();
                    },
                    (err: Rest.Failed) => {
                        this.onUploadFailure(err.message);
                        const customOnUploadFailure = this.getCustomOnUploadFailure(params);
                        customOnUploadFailure && customOnUploadFailure(err);
                        if (this.throwUploadError) {
                            throw err;
                        }
                    },
                )
                .finally(() => {
                    const customOnUploadFinally = this.getCustomOnUploadFinally(params);
                    customOnUploadFinally && customOnUploadFinally();
                });
        } else {
            this.onUploadFailure("Failed to upload " + this.fileFormName);
            const customOnUploadFailure = this.getCustomOnUploadFailure(params);
            customOnUploadFailure
                && customOnUploadFailure(
                    new Rest.Failed(null, "Failed to upload " + this.fileFormName, 0),
                );
        }
    }

    /** Call this when you start the upload process, to give an indication to the user. */
    onUploadStart(): void {
        Dom.setContent(this.onUploadNodeFileText, this.getSelectedFile().name);
        Dom.setContent(this.onUploadNodeStatusText, "Uploading...");
        Dom.show(this.onUploadNode);
        Dom.hide(this.fileInputForm.selectionNode);
        Dom.hide(this.fileInputForm.selectedFilesNode);
    }

    /** Call when the upload completes successfully to give an indication to the user. */
    onUploadSuccess(): void {
        Dom.addClass(this.onUploadNode, "upload-complete");
        Dom.setContent(this.onUploadNodeStatusText, "Upload succeeded!");
        Dom.setContent(this.resetNode, "Reset");
        Dom.show(this.resetNode);
    }

    /**
     * Call when the upload fails to give an indication to the user. The optional error message
     * will be displayed.
     */
    onUploadFailure(error = ""): void {
        Dom.addClass(this.onUploadNode, "upload-complete");
        Dom.setContent(this.onUploadNodeStatusText, "Upload failed");
        Dom.setContent(this.resetNode, "Try again");
        Dom.show(this.resetNode);
        this.setError(error);
    }

    private getUploadEndpoint(params: UploadFileParams): string {
        return params.uploadEndPoint ? params.uploadEndPoint : this.uploadEndPoint;
    }

    private getUploadParams(params: UploadFileParams): { [paramName: string]: unknown } {
        return params.uploadParams ? params.uploadParams : this.uploadParams;
    }

    private getCustomOnUploadStart(params: UploadFileParams): () => void {
        return params.customOnUploadStart ? params.customOnUploadStart : this.customOnUploadStart;
    }

    private getCustomOnUploadSuccess(params: UploadFileParams): () => void {
        return params.customOnUploadSuccess
            ? params.customOnUploadSuccess
            : this.customOnUploadSuccess;
    }

    private getCustomOnUploadFailure(params: UploadFileParams): (err: Rest.Failed) => void {
        return params.customOnUploadFailure
            ? params.customOnUploadFailure
            : this.customOnUploadFailure;
    }

    private getCustomOnUploadFinally(params: UploadFileParams): () => void {
        return params.customOnUploadFinally
            ? params.customOnUploadFinally
            : this.customOnUploadFinally;
    }

    setError(error: string): void {
        this.fileInputForm.setError(error);
    }

    /** Get the currently-selected file. Useful if you're building your own form. */
    getSelectedFile(): File {
        return this.hasSelectedFile() ? this.fileInputForm.getSelectedFiles()[0] : null;
    }

    hasSelectedFile(): boolean {
        return this.fileInputForm.hasSelectedFiles();
    }

    /** Get form data for the currently-selected file. */
    getFileFormData(): FormData {
        if (this.hasSelectedFile()) {
            const form = new FormData();
            form.append(this.fileFormName, this.getSelectedFile());
            return form;
        }
        return null;
    }

    getNode(): HTMLElement {
        return this.fileInputForm.node;
    }

    reset(): void {
        this.fileInputForm.reset();
    }

    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}
