import { ColumnAnalysis } from "Everlaw/Model/Upload/Metadata/ColumnAnalysis";
import { CombinationAnalysis } from "Everlaw/Model/Upload/Metadata/CombinationAnalysis";
import MergeAnalysis = require("Everlaw/Model/Upload/Metadata/MergeAnalysis");
import { NameAnalysis } from "Everlaw/Model/Upload/Metadata/NameAnalysis";
import NameStatus = require("Everlaw/UI/Upload/Metadata/NameStatus");
import Type = require("Everlaw/Type");
import { TypeFormat } from "Everlaw/UI/Upload/Metadata/TypeFormat";

/**
 * Corresponds to MetadataAnalyses.java.
 */
class MetadataAnalyses {
    version: number;
    numDocs: number;
    // Only for overlay uploads. Name of fields that have values on the documents to be overlaid.
    existingFields: string[];
    hasMixedDayMonthOrdering: boolean;
    dateTimeFormatsWithSamples: { [dateTimeFormat: string]: MetadataAnalyses.FormatSample };
    private nameRestrictions: NameStatus.NameRestrictions;
    private nameAnalyses: { [headerClass: string]: { [typeName: string]: NameAnalysis[] } } = {};
    private mergeAnalyses: { [mergeAnalysisId: string]: MergeAnalysis } = {};
    private combAnalysesByTfIds: {
        [typeFormatId: string]: { [otherId: string]: CombinationAnalysis };
    } = {};
    private columnAnalyses: { [header: string]: ColumnAnalysis } = {};
    constructor(params: any, rejectedCombinationAnalyses: Set<string>) {
        this.version = params.version;
        this.numDocs = params.numDocs;
        this.nameRestrictions = params.nameRestrictions; // set before adding analyses
        this.existingFields = params.existingFields;
        this.hasMixedDayMonthOrdering = params.hasMixedDayMonthOrdering;
        this.dateTimeFormatsWithSamples = params.dateTimeFormatsWithSamples;
        this.addNameAnalyses(params.nameAnalyses);
        this.addMergeAnalyses(params.mergeAnalyses);
        this.addCombinationAnalyses(
            Object.values(params.combinationAnalyses || {}),
            rejectedCombinationAnalyses,
        );
        this.addColumnAnalyses(params.columnAnalyses);
    }
    addNameAnalyses(nameAnalysesJson: any) {
        Object.entries(nameAnalysesJson || {}).forEach(([headerClass, analysesByType]) => {
            this.nameAnalyses[headerClass] = {};
            Object.entries(analysesByType || {}).forEach(([typeName, analyses]) => {
                const typ = Type.TYPE_BY_NAME[typeName];
                this.nameAnalyses[headerClass][typeName] = analyses.map(
                    (a) =>
                        new NameAnalysis(
                            a.name,
                            a.count,
                            a.similarity,
                            typ,
                            this.nameRestrictions[a.name],
                        ),
                );
            });
        });
    }
    mergeAnalysis(mergeAnalysisId: string) {
        return this.mergeAnalyses[mergeAnalysisId];
    }
    addMergeAnalyses(mergeAnalyses: { [analysisId: string]: MergeAnalysis }) {
        Object.entries(mergeAnalyses || {}).forEach(([id, ma]) => (this.mergeAnalyses[id] = ma));
    }
    combinationAnalysis(selectedTypeFormat: TypeFormat, otherSelectedTypeFormat: TypeFormat) {
        if (!otherSelectedTypeFormat) {
            throw new Error("Asking for CombinationAnalysis for a single-column ColumnCombination");
        }
        const forFirst = this.combAnalysesByTfIds[selectedTypeFormat.id];
        return forFirst ? forFirst[otherSelectedTypeFormat.id] : null;
    }
    addCombinationAnalyses(analysesJson: any, rejectedCombinationAnalyses: Set<string>) {
        analysesJson.forEach((analysisJson: any) => {
            const analysis = new CombinationAnalysis(analysisJson);
            if (rejectedCombinationAnalyses.has(analysis.id())) {
                analysis.markRejected();
            }
            analysis.typeAnalysisIds.forEach((taId: string) => {
                let forwardsSide = this.combAnalysesByTfIds[taId];
                if (!forwardsSide) {
                    forwardsSide = {};
                    this.combAnalysesByTfIds[taId] = forwardsSide;
                }
                const otherId = analysis.otherId(taId);
                forwardsSide[otherId] = analysis;
                let otherSide = this.combAnalysesByTfIds[otherId];
                if (!otherSide) {
                    otherSide = {};
                    this.combAnalysesByTfIds[otherId] = otherSide;
                }
                otherSide[taId] = analysis;
            });
        });
    }
    columnAnalysis(header: string) {
        return this.columnAnalyses[header];
    }
    private addColumnAnalyses(analysesJson: { [name: string]: any }) {
        Object.entries(analysesJson || {}).forEach(([header, a]) => {
            this.columnAnalyses[header] = new ColumnAnalysis(a, this.numDocs, this.nameAnalyses);
        });
    }
    getColumnHeaders() {
        return Object.keys(this.columnAnalyses);
    }
    getNameRestrictions(name: string) {
        return this.nameRestrictions[name];
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module MetadataAnalyses {
    /**
     * Corresponds to ExploratoryParser.java#FormatSample.
     */
    export interface FormatSample {
        format: string;
        raw: string;
        parsedDisplay: string;
        success: boolean;
    }
}

export = MetadataAnalyses;
