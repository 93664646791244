import { ColorTokens, EverColor } from "design-system";
import Type_type = require("Everlaw/Type");
import UploadUI = require("Everlaw/UI/Upload/Util/UploadUI");

const MERGE_WARNING = "Mapped to another header";
const MERGE_TOOLTIP = `Multiple load file headers are mapped to this display name, causing
        conflicting values`;

/**
 * A description of the status of a particular name-type pair for a metadata field.
 *
 * Corresponds to the NameStatus enum in WebMetadataService.java.
 */
interface NameStatus {
    name: string;
    color: string;
    iconClass: (isMerge: boolean) => string;
    tooltip: (isMerge: boolean) => string;
    valid?: boolean;
    canonical?: boolean;
    new?: boolean;
    summary?: (isMerge: boolean) => string;
}

export interface NameRestriction {
    status: NameStatusType;
    requiredType: string;
}

export type NameRestrictions = { [header: string]: NameRestriction[] };

export const VALID: { [status: string]: NameStatus } = {
    CANONICAL: {
        name: "Canonical",
        color: EverColor.EVERBLUE_30,
        iconClass: (isMerge) => (isMerge ? UploadUI.WARNING_ICON : UploadUI.SUCCESS_ICON),
        valid: true,
        canonical: true,
        summary: (isMerge) => (isMerge ? MERGE_WARNING : ""),
        tooltip: (isMerge) => (isMerge ? MERGE_TOOLTIP : ""),
    },
    SEMANTIC: {
        name: "Semantic",
        color: EverColor.PARCHMENT_60,
        iconClass: (isMerge) => (isMerge ? UploadUI.WARNING_ICON : UploadUI.SUCCESS_ICON),
        valid: true,
        summary: (isMerge) => (isMerge ? MERGE_WARNING : "Custom field"),
        tooltip: (isMerge) =>
            isMerge
                ? MERGE_TOOLTIP
                : `This is a custom field name. Mapping
                to a standard display name is recommended.`,
    },
};

/**
 * NEW represents names that do not exist on the project yet. It does not have a backend analogue
 * because it is the default when none of the other statuses apply.
 */
export const NEW: NameStatus = {
    name: "New",
    color: ColorTokens.WARNING,
    iconClass: () => UploadUI.WARNING_ICON,
    valid: true,
    new: true,
    summary: (isMerge) => (isMerge ? MERGE_WARNING : "New custom field"),
    tooltip: (isMerge) =>
        isMerge
            ? MERGE_TOOLTIP
            : `A new custom field will be created in the
            database. Mapping to a standard field name is recommended.`,
};

function invalid(summary: string, tooltip: () => string): NameStatus {
    return {
        name: "Invalid",
        color: EverColor.RED_30,
        iconClass: () => UploadUI.INVALID_ICON,
        summary: () => summary,
        tooltip,
    };
}

function simpleInvalid(reqType: string) {
    return invalid("Valid only for " + reqType, () => "");
}

/**
 * Should stay in sync with WebMetadataService.java#NameStatus.
 */
enum NameStatusType {
    CANONICAL = "CANONICAL",
    SEMANTIC = "SEMANTIC",
    USER = "USER",
    INVALID_NAME = "INVALID_NAME",
}

/**
 * INVALID_TYPE represents names that are assigned to multiple columns of different types in the
 * same upload. It does not have a backend analogue because the backend doesn't know which names
 * will be misused in this way during configuration.
 */
export const INVALID_TYPE = invalid(
    "Mapped to another type",
    () => "Multiple load file headers with different types are mapped to this display name",
);

/**
 * Checks for restrictions on the given `typ` amongst `restrictions`. Returns the NameStatus
 * describing the restriction (or lack thereof).
 */
export function of(typ: Type_type.FieldType, restrictions: NameRestriction[]): NameStatus {
    if (!(restrictions && restrictions.length)) {
        return NEW;
    }
    for (const restriction of restrictions) {
        if (restriction.status === NameStatusType.INVALID_NAME) {
            return invalid("Valid only for identity fields", () => "");
        }
        if (typ !== null && !typesCompatible(restriction, typ)) {
            return simpleInvalid(restriction.requiredType);
        }
    }
    if (restrictions[0].status in VALID) {
        return VALID[restrictions[0].status];
    }
    return NEW;
}

/**
 * Corresponds to `NameRestriction#compatibleWith` on the back-end.
 */
function typesCompatible(restriction: NameRestriction, other: Type_type.FieldType): boolean {
    if (restriction.status === NameStatusType.USER) {
        return other.viewableAs(Type_type.TYPE_BY_NAME[restriction.requiredType]);
    }
    return other.name === restriction.requiredType;
}
