import Document = require("Everlaw/Document");
import DocumentMutator = require("Everlaw/DocumentMutator");
import Is = require("Everlaw/Core/Is");
import Str = require("Everlaw/Core/Str");
import Type = require("Everlaw/Type");
import { TypeStructure } from "Everlaw/Type";

/**
 * A properly-typed Eql object.
 * All argument types are checked in the constructor, and may be rich objects for type checking
 * (for example, the actual user object should be passed, rather than an id).
 * When making Rest requests, use toJson, because dojo will stringify the raw
 * javascript array.
 * When updating the url hash, use toString.
 * For a list of properties and their arguments, see Property.ts.
 *
 * For now, when constructing Eql, it is your responsibility to identify the
 * correct search term for the property. In the future, the search terms may
 * expose an interface to create Eql by passing arguments to them directly.
 */
class Eql<T> {
    /**
     * This is the name of the EQL function for this property. If not explicitly overridden,
     * this defaults to the lower-cased class name (see {@link Property#setEqlNameAndRecordProperties}).
     * Corresponds to `EqlFunction.name` on the backend.
     */
    static EQL_NAME: string;

    /**
     * The default display name is `Str.camelToHuman(EQL_NAME)`. For a different name, override this.
     */
    protected static DISPLAY_NAME: string;

    /**
     * Specifies the acceptable argument types for this property. Can be either a single `Type` or a
     * hash of key-value pairs, where each key matches the corresponding argument name in the Java
     * analog of this property, and the value is the appropriate `Type`.
     */
    static ARG_TYPE_STRUCTURE: TypeStructure = Type.NULL;

    /**
     * Override with any permission check needed to use this property.
     */
    static isAccessible(): boolean {
        return true;
    }

    static display(): string {
        if (this.DISPLAY_NAME) {
            return this.DISPLAY_NAME;
        } else {
            return Str.camelToHuman(this.EQL_NAME);
        }
    }

    /**
     * This method exists to translate the sort signature of a SearchResult into a human-readable
     * version which includes the names of corresponding columns on the results table. It only
     * makes sense to call this on a Property which can be sorted.
     *
     * The parameter sortArg should be the sort argument in a particular sort signature.
     */
    static displaySortArg(sortArg: any): string {
        return sortArg.toString();
    }

    static validate(value: any): boolean {
        const res = Type.traverse(this.ARG_TYPE_STRUCTURE, value, "isValidValue");
        if (!res) {
            return false;
        }
        if (Is.boolean(res)) {
            return res;
        }
        if (Is.array(res)) {
            return res.every((v) => !!v);
        }
        return Object.values(res).every((v) => !!v);
    }

    property: Eql.Property<T> = this.constructor as typeof Eql; // Used to invoke child-class static methods
    args: T;

    /**
     * @param args arguments for this property, defined in Property.ts
     * @param termName used to specify which search term should be used to handle this Eql. This
     * should generally be provided for any `Eql` that will potentially be displayed in a search
     * builder or search results page.
     * @param argsAreRaw if true, apply `Type#fromJson` to any typed values in `args`
     */
    constructor(
        args: T,
        public termName?: string,
        argsAreRaw?: boolean,
    ) {
        this.args = argsAreRaw ? this.fromJson(args) : args;
        // type-check the args
        if (!this.property.validate(this.args)) {
            throw new EqlError(this);
        }
    }

    toJson(): any {
        const res = [this.property.EQL_NAME, this._toJson(this.args)];
        if (this.termName) {
            res.push(this.termName);
        }
        return res;
    }

    protected fromJson(what: any) {
        return this._fromJson(what);
    }

    toString() {
        return JSON.stringify(this.toJson());
    }

    private _toJson(what: any) {
        return Type.traverse(this.property.ARG_TYPE_STRUCTURE, what, "toJsonValue");
    }

    private _fromJson(what: any) {
        return Type.traverse(this.property.ARG_TYPE_STRUCTURE, what, "fromJsonValue");
    }

    /**
     * Returns whether the EQL expression matches the given document & current mutator (for coding rules).
     */
    matches(doc: Document, mutator: DocumentMutator) {
        return false;
    }

    /**
     * When normalizing eql for logical wrappers (flattenGroups, pluckParent, etc.), we might need
     * to set this.termName. This method returns true when this.termName is not set to signal that
     * the field can be overridden.
     */
    hasWidget(widget = this.property.EQL_NAME): boolean {
        return !this.termName || this.termName === widget;
    }
}

/**
 * Custom error class. By default, Bugsnag groups errors by the surrounding source code. This
 * resulted in various eql-related bugs with differing causes being grouped together into one giant
 * bug report. Checking for this error type in `headHeader.jsp` lets us customize how we group the
 * errors.
 */
class EqlError extends Error {
    constructor(eql: Eql.Any) {
        // Store the property name at the start of the message, for later use as the grouping hash.
        super(
            `${eql.property.display()}; Invalid arguments ${JSON.stringify(eql.args)} for eql property ${eql.property.display()}`,
        );
        this.name = "EqlError";
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module Eql {
    /**
     * Use this type when a variable can be an Eql<T> for any T.
     */
    export interface Any extends Omit<Eql<unknown>, "new" | "property"> {
        property: Omit<Property<unknown>, "new" | "prototype">;
    }

    // Interface implemented by static side of Eql class. This is used instead of (typeof Eql)
    // to describe the type of property classes with a specific argument type.
    export interface Property<T> {
        /**
         * See {@link Eql#constructor}.
         */
        new (args: T, termName?: string, argsAreRaw?: boolean): Eql<T>;
        prototype: Eql<T>;
        /**
         * See {@link Eql#EQL_NAME}.
         */
        EQL_NAME: string;
        /**
         * See {@link Eql#isAccessible}.
         */
        isAccessible(): boolean;
        /**
         * See {@link Eql#ARG_TYPE_STRUCTURE}.
         */
        ARG_TYPE_STRUCTURE: TypeStructure;
        /**
         * See {@link Eql#display}.
         */
        display(): string;
        /**
         * See {@link Eql#validate}.
         */
        validate(value: T): boolean;
    }
}

export = Eql;
