import Base = require("Everlaw/Base");
import { ColorTokens, EverColor } from "design-system";
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import { Color } from "Everlaw/ColorUtil";

const dateRangeColor = EverColor.AQUAMARINE;

class ChronologyCategory extends Base.Object {
    get className() {
        return "ChronologyCategory";
    }
    override id: ChronologyCategory.Id;
    name: string;
    type: ChronologyCategory.CategoryType;
    colorIndex: number;
    chronLabels: number[];
    chronologyId: number;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.type = ChronologyCategory.CategoryType[<string>params.type];
    }
    override display() {
        return this.name;
    }
    isDateRange() {
        return this.type === ChronologyCategory.CategoryType.DATERANGE;
    }
    isProfile() {
        return this.type === ChronologyCategory.CategoryType.PROFILE;
    }
    getColor() {
        return Color.fromHexString(
            this.type === ChronologyCategory.CategoryType.DATERANGE
                ? dateRangeColor
                : ChronologyCategory.colors[this.colorIndex % ChronologyCategory.colors.length],
        );
    }
    override compare(other: ChronologyCategory) {
        // sort Events (DateRanges) first and People last
        if (this.isDateRange() && !other.isDateRange()) {
            return -1;
        } else if (!this.isDateRange() && other.isDateRange()) {
            return 1;
        } else if (this.isProfile() && !other.isProfile()) {
            return 1;
        } else if (!this.isProfile() && other.isProfile()) {
            return -1;
        }
        return this.name.localeCompare(other.name);
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ChronologyCategory {
    export type Id = number & Base.Id<"ChronologyCategory">;
    export enum CategoryType {
        DEFAULT,
        DATERANGE,
        PROFILE,
    }
    export type Type = CategoryType;
    export const Type = CategoryType;

    // Beware: These colors are duplicated on the backend!
    // Don't use eggplant or sakura (reserved for Drafts and Depos)
    export const colors: string[] = [
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_1,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_2,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_3,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_4,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_5,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_6,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_7,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_8,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_9,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_10,
        ColorTokens.OBJECT_CHRONOLOGY_CATEGORY_11,
    ];

    export function create(
        chronId: number,
        name: string,
        onSuccess?: (newCat: ChronologyCategory) => void,
    ) {
        Rest.post(Project.CURRENT.url("chronology/createCategory.rest"), {
            chronId: chronId,
            categoryName: name,
        }).then((newChronCategory) => {
            const newCategory = Base.set(ChronologyCategory, newChronCategory);
            onSuccess && onSuccess(newCategory);
        });
    }
    export function rename(
        chronCategory: ChronologyCategory,
        name: string,
        onSuccess?: () => void,
        onError?: () => void,
    ) {
        Rest.post(Project.CURRENT.url("chronology/renameCategory.rest"), {
            categoryId: chronCategory.id,
            name: name,
        }).then(
            () => onSuccess && onSuccess(),
            (e) => {
                onError && onError();
                throw e;
            },
        );
    }
}

export = ChronologyCategory;
