import Project = require("Everlaw/Project");
import BasicRadio = require("Everlaw/UI/BasicRadio");
import SearchResult = require("Everlaw/SearchResult");
import Dom = require("Everlaw/Dom");
import Base = require("Everlaw/Base");
import Table = require("Everlaw/Table");
import Icon = require("Everlaw/UI/Icon");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Document = require("Everlaw/Document");
import Rest = require("Everlaw/Rest");
import Eql = require("Everlaw/Eql");
import Util = require("Everlaw/Util");
import Bugsnag = require("Everlaw/Bugsnag");
import { Flag } from "Everlaw/Model/Processing/ProcessingDefs";
import { CellCallbackParam, RowData } from "Everlaw/Table";
import { DataPrimitive } from "Everlaw/Base";

interface MalwareListObj {
    id: number;
    beginBates: string;
}

interface MalwareResponse {
    results: MalwareListObj[]; // backend endpoint limits it to 10 but there can be more, count has the true count
    count: number;
    // the following are required if we needed to call getMalwareCountsFromEql.rest
    search?: SearchResult; // searchResult from the given EQL, may be filtered for selected docIds
}

export default class MalwareWarningDialog {
    readonly withFlagged: string = "With flagged documents";
    readonly withoutFlagged: string = "Without flagged documents";
    onInclude: () => void;
    onExclude: () => void;

    constructor(
        project: Project,
        resp: MalwareResponse,
        onInclude: () => void,
        canExclude = false,
        onExclude?: () => void,
    ) {
        this.onInclude = onInclude;
        this.onExclude = onExclude;
        const store = new Base.SimpleStore("MalwareDocs", false);
        resp.results.forEach((r) => store.add(new Base.DataPrimitive(r, r.id)));
        const tableSearchLink = Dom.a(
            {
                href: "",
                class: "everblue-link condensed-bold",
                style: { "font-size": "18px" },
                target: "_blank",
            },
            resp.count,
        );
        const dialogSearchLink = Dom.a(
            {
                href: "",
                class: "everblue-link",
                target: "_blank",
            },
            "See full list in the results table.",
        );
        // docs are supplied to this constructor either through a search, or a list of doc ids in resp.results
        // import Property here to avoid circular dependency
        import("Everlaw/Property").then((Property) => {
            const searchLink = resp.search
                ? `search.do#id=${resp.search.id}`
                : `search.do?eql=${new Property.Docs(
                      resp.results.map((r) => r.id as Document.Id),
                      "docs",
                  ).toString()}`;
            tableSearchLink.href = project.url(searchLink);
            dialogSearchLink.href = project.url(searchLink);
        });
        const table = new Table({
            header: [
                "Flagged documents",
                Dom.span({ class: "h-spaced-8 right" }, new Icon("file").node, tableSearchLink),
            ],
            maxHeight: "210px",
            store: store,
            columns: [{}, { width: "75px" }],
            cells: [
                (p: CellCallbackParam<DataPrimitive<MalwareListObj>, RowData>) => {
                    const href = Dom.a(
                        {
                            href: Util.reviewURL(project.id, p.o.id as number),
                            class: "everblue-link",
                            target: "_blank",
                        },
                        p.o.data.beginBates,
                    );
                    Dom.place(href, p.td);
                },
                () => {},
            ],
        });
        let radio;
        let qd = undefined;
        if (canExclude) {
            radio = new BasicRadio([this.withoutFlagged, this.withFlagged], true);
            radio.onSelect = () => qd.disableSubmit(false);
            Dom.addClass(radio, "v-spaced-8");
        }
        qd = QueryDialog.create({
            title: "Potentially malicious documents",
            submitText: "Continue",
            prompt: Dom.div(
                "Some native documents have been flagged as potentially malicious. Please "
                    + "take precaution when downloading or exporting these documents, as they may "
                    + "contain malware.",
            ),
            closable: true,
            destroyOnClose: true,
            body: Dom.div(
                { class: "v-spaced-16" },
                table.node,
                resp.count !== resp.results.length
                    ? Dom.div(
                          { class: "malware-dialog__some-docs" },
                          "Only showing some documents. ",
                          dialogSearchLink,
                      )
                    : null,
                canExclude
                    ? Dom.div(
                          { class: "v-spaced-8" },
                          Dom.div(
                              { class: "malware-dialog__radio-label" },
                              "Select how to continue",
                          ),
                          radio.getNode(),
                      )
                    : null,
            ),
            style: { width: "452px" },
            onSubmit: () => {
                if (!canExclude || radio.getSelected().id === this.withFlagged) {
                    this.onInclude();
                    const action = canExclude
                        ? "Malware Warning with Exclude Option"
                        : "Malware Warning with No Exclude Option";
                    ga_event("Malware", action, "Include");
                } else {
                    this.onExclude();
                    ga_event("Malware", "Malware Warning with Exclude Option", "Exclude");
                }
                return true;
            },
            onCancel: () => {
                console.log("oncancel");
                const action = canExclude
                    ? "Malware Warning with Exclude Option"
                    : "Malware Warning with No Exclude Option";
                ga_event("Malware", action, "Cancel");
                return true;
            },
        });
        qd.disableSubmit(canExclude);
    }
}

export function checkDocsForMalware(
    docs: Document[],
    onInclude: () => void,
    onExclude?: () => void,
): void {
    const flaggedDocs = docs.filter((d) => d.hasFlag(Flag.FlaggedMalicious));
    if (flaggedDocs.length < 1) {
        onInclude();
    } else {
        new MalwareWarningDialog(
            Project.CURRENT,
            {
                results: flaggedDocs.map((d) => {
                    return { id: d.id, beginBates: d.beginBates };
                }),
                count: flaggedDocs.length,
            },
            onInclude,
            flaggedDocs.length !== docs.length,
            onExclude,
        );
    }
}

interface MalwareParams {
    projectId?: Project.Id;
    eql: string | Eql.Any;
    fromAll?: boolean;
    docIds?: Base.Id<number>[] | number[];
    onInclude: () => void;
    onExclude?: () => void;
}

export function checkForMalware(params: MalwareParams): void {
    if (!params.eql) {
        // this shouldn't occur unless there's a bug we need to fix to fully integrate this malware check
        // in the case it does occur, allow access as to not affect user workflow, but notify us on Bugsnag
        Bugsnag.notify(Error("No eql parameter for checkForMalware function call"));
        params.onInclude();
        return;
    }
    const project =
        params.projectId == null ? Project.CURRENT : Base.get(Project, params.projectId);
    Rest.post(project.url("search/getMalwareCountsFromEql.rest"), {
        eql: params.eql,
        fromAll: params.fromAll,
        docIds: params.docIds,
    }).then((resp: MalwareResponse) => {
        if (resp.count < 1) {
            params.onInclude();
        } else {
            new MalwareWarningDialog(
                project,
                resp,
                params.onInclude,
                !!params.onExclude,
                params.onExclude,
            );
        }
    });
}
