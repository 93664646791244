import { Color } from "Everlaw/ColorUtil";
import Base = require("Everlaw/Base");
import { ColorTokens } from "design-system";
import Dom = require("Everlaw/Dom");

export class Flag extends Base.Object implements Base.Colored {
    /*
     * This set mirrors those found in ProcessingFlag.java.
     */
    static readonly IsContainer = new Flag("IsContainer", "Container Document", 1);
    static readonly IsEncrypted = new Flag("IsEncrypted", "Encrypted", 1 << 1);
    static readonly NISTDuplicate = new Flag("NISTDuplicate", "NIST Duplicate", 1 << 2);
    static readonly HasOCR = new Flag("HasOCR", "Has OCR", 1 << 3);
    static readonly PlaceholderPDF = new Flag("PlaceholderPDF", "Has Placeholder PDF", 1 << 4);
    static readonly EmptyText = new Flag("EmptyText", "Empty Text", 1 << 5);
    static readonly Unknown = new Flag("Unknown", "Unknown Document Type", 1 << 6);
    static readonly OpenedWithPassword = new Flag(
        "OpenedWithPassword",
        "Opened With Password/Key",
        1 << 7,
    );
    static readonly OCRError = new Flag("OCRError", "OCR Error", 1 << 8);
    static readonly MetadataError = new Flag("MetadataError", "Metadata Error", 1 << 9);
    static readonly EmbeddedError = new Flag("EmbeddedError", "Embedded File Error", 1 << 10);
    static readonly IsEmbed = new Flag("IsEmbed", "Embedded File", 1 << 11);
    static readonly HasTranscription = new Flag("HasTranscription", "Has Transcription", 1 << 13);
    static readonly ValidTranscription = new Flag(
        "ValidTranscription",
        "Has Valid Transcription",
        1 << 14,
    );
    static readonly FlaggedMalicious = new Flag("FlaggedMalicious", "Flagged Malicious", 1 << 29);
    static readonly HasTranscodedOutput = new Flag(
        "HasTranscodedOutput",
        "Has Transcoded Output",
        1 << 30,
    );
    static readonly PartialSupport = new Flag("PartialSupport", "Partial Support", 1 << 31);
    get className() {
        return "ProcessingFlag";
    }
    private constructor(
        id: string,
        public name: string,
        public bitMask: number,
    ) {
        super({ id });
        Base.add(this);
    }
    getColor() {
        return Color.fromEverColor(ColorTokens.OBJECT_PROCESSING_FLAG);
    }
    override display() {
        return this.name;
    }
}

export class Stage extends Base.Object implements Base.Colored {
    static readonly EXAMINE = new Stage("EXAMINE", "Examine", 0);
    static readonly PDF = new Stage("PDF", "PDF", 1);
    static readonly TEXT = new Stage("TEXT", "Text", 2);
    static readonly ALL = [Stage.EXAMINE, Stage.PDF, Stage.TEXT];
    private static readonly ERROR_COLOR = Color.fromEverColor(ColorTokens.DANGER);
    get className() {
        return "ProcessingStage";
    }
    override id: string;
    private constructor(
        id: string,
        public name: string,
        public order: number,
    ) {
        super({ id });
        Base.add(this);
    }
    getColor() {
        return Stage.ERROR_COLOR;
    }
    override compare(other: Stage) {
        return this.order - other.order;
    }
    override display() {
        return this.name;
    }
}

export function pdfOptionDisplay(option: PdfOption) {
    switch (option) {
        case PdfOption.DEFAULT:
            return "All documents, excluding file types (e.g. Excel) that do not convert well to PDF";
        case PdfOption.ALL:
            return "All documents";
        case PdfOption.NONE:
            return "No documents";
        default:
            return "Unknown";
    }
}

export enum ImageInlining {
    ALL = "ALL",
    SMART = "SMART",
    STRICT = "STRICT",
}

export function inlineDisplay(inline: ImageInlining) {
    switch (inline) {
        case ImageInlining.ALL:
            return "Inline all images found in emails";
        case ImageInlining.SMART:
            return "Smart inline images found in emails";
        case ImageInlining.STRICT:
            return "Extract all images found in emails";
        default:
            return "Unknown";
    }
    return inline ? "Inline all images found in emails" : "Extract attached images as children";
}

export enum ImageInliningSubcontent {
    ALL = "All images will be displayed inline in PDFs",
    SMART = "Most images originally intended to be viewed inline (e.g. logos in email signatures) will be displayed inlined in PDFs",
    STRICT = "All images will be extracted as separate attachments",
}

export enum Deduplication {
    NONE,
    ALL,
    WITHIN_CUSTODIAN,
}

export function deduplicationDisplay(dedupe: Deduplication) {
    switch (dedupe) {
        case Deduplication.NONE:
            return "None";
        case Deduplication.ALL:
            return "Global";
        case Deduplication.WITHIN_CUSTODIAN:
            return "By custodian";
        default:
            return "Unknown";
    }
}

export function deduplicationSubContent(dedupe: Deduplication) {
    switch (dedupe) {
        case Deduplication.NONE:
            return "No documents will be deduplicated";
        case Deduplication.ALL:
            return [
                "Deduplication on a family level ",
                Dom.b({ class: "semi-bold" }, "across"),
                ` all custodians in the database.
                If two or more custodians contain the same documents, only one set will be uploaded.`,
            ];
        case Deduplication.WITHIN_CUSTODIAN:
            return [
                "Deduplication on a family level ",
                Dom.b({ class: "semi-bold" }, "within"),
                ` individual custodians in the
                database. If two or more custodians contain the same document, both sets will be uploaded.`,
            ];
        default:
            return "Unknown";
    }
}

export enum AssociateWithHoldNotice {
    NO,
    YES,
}

export function holdNoticeAssocDisplay(holdNoticeSetting: AssociateWithHoldNotice) {
    switch (holdNoticeSetting) {
        case AssociateWithHoldNotice.NO:
            return "No, this upload is not associated with a hold notice";
        case AssociateWithHoldNotice.YES:
            return "Yes, this upload contains documents collected from custodians in a hold notice";
        default:
            return "Unknown";
    }
}

export enum PdfOption {
    DEFAULT = "DEFAULT",
    ALL = "ALL",
    NONE = "NONE",
    DATASET = "DATASET",
}

export enum PageSizeOption {
    A4 = "A4",
    LETTER = "Letter",
}

export enum SpeakerNotesOption {
    INCLUDE = "INCLUDE",
    INCLUDE_STREAMLINED = "INCLUDE_STREAMLINED",
    EXCLUDE = "EXCLUDE",
    DATASET_DEFAULT = "DATASET_DEFAULT",
}

export const enum ChatSegmentationOption {
    MAX_100 = "MAX_100",
    MAX_1000 = "MAX_1000",
}

export const SPEAKER_NOTES_OPTION_TITLE = "PowerPoint speaker notes";

export function speakerNotesDisplay(speakerNotesOption: SpeakerNotesOption) {
    switch (speakerNotesOption) {
        case SpeakerNotesOption.INCLUDE:
            return "Include speaker notes with original formatting";
        case SpeakerNotesOption.INCLUDE_STREAMLINED:
            return "Include speaker notes with optimized formatting";
        case SpeakerNotesOption.EXCLUDE:
            return "Exclude speaker notes from PDF and text file";
        case SpeakerNotesOption.DATASET_DEFAULT:
            return "Dataset default";
        default:
            return "Unknown";
    }
}

export function speakerNotesSubcontent(speakerNotesOption: SpeakerNotesOption) {
    switch (speakerNotesOption) {
        case SpeakerNotesOption.INCLUDE:
            return "PowerPoint notes pages will be displayed in the PDF without modification";
        case SpeakerNotesOption.INCLUDE_STREAMLINED:
            return (
                "Slides will be scaled to optimize legibility, with speaker notes text displayed "
                + "under the slide. Any additional objects on the notes page, such as headers and "
                + "footers, will be excluded from the PDF."
            );
        case SpeakerNotesOption.EXCLUDE:
            return "Slides will be full-page. The native file will still include speaker notes.";
        default:
            return "Unknown";
    }
}

export function imageProxyDisplay(useImageProxy: boolean): string {
    return useImageProxy ? "Fetch hyperlinked images" : "Do not fetch hyperlinked images";
}

// This should match the constant in ProcessingConfiguration.java
export const OCR_AUTODETECT_ID = "auto";
export const OCR_AUTODETECT_NAME = "Autodetect";
export const OCR_LANGUAGES = new Base.SimpleStore<Base.Primitive<string>>("OcrLanguages", false);

// ISO/DIS 639-3 codes supported by Nuance.
// See http://csdklab.com/WebHelp/recapi/group__MOD__CHR.html#gaf4bc3b1b5f0158a76bb95bcf00ed6af2
Object.entries({
    eng: "English",
    deu: "German",
    fra: "French",
    nld: "Dutch",
    nor: "Norwegian",
    swe: "Swedish",
    fin: "Finnish",
    dan: "Danish",
    isl: "Icelandic",
    por: "Portuguese",
    spa: "Spanish",
    cat: "Catalan",
    glg: "Galician",
    ita: "Italian",
    mlt: "Maltese",
    ell: "Greek",
    pol: "Polish",
    ces: "Czech",
    slk: "Slovakian",
    hun: "Hungarian",
    slv: "Slovenian",
    hrv: "Croatian",
    ron: "Romanian",
    sqi: "Albanian",
    tur: "Turkish",
    est: "Estonian",
    lav: "Latvian",
    lit: "Lithuanian",
    qsl: "Serbian (Latin)",
    srp: "Serbian (Cyrillic)",
    mkd: "Macedonian",
    mol: "Moldavian",
    bul: "Bulgarian",
    bel: "Belarusian",
    ukr: "Ukrainian",
    rus: "Russian",
    che: "Chechen",
    kbd: "Kabardian",
    afr: "Afrikaans",
    aym: "Aymara",
    eus: "Basque",
    bem: "Bemba",
    bla: "Blackfoot",
    bre: "Breton",
    qbp: "Portuguese (Brazilian)",
    bgt: "Bugotu",
    cha: "Chamorro",
    tsn: "Tswana",
    cos: "Corsican",
    cro: "Crow",
    qes: "Eskimo",
    fao: "Faroese",
    fij: "Fijian",
    fry: "Frisian",
    fur: "Friulian",
    gle: "Gaelic Irish",
    gla: "Gaelic Scottish",
    lug: "Luganda",
    grn: "Guarani",
    hni: "Hani",
    haw: "Hawaiian",
    ind: "Indonesian",
    csb: "Kashubian",
    wbm: "Kawa",
    kik: "Kikuyu",
    kon: "Kongo",
    kpe: "Kpelle",
    kur: "Kurdish",
    lat: "Latin",
    lua: "Luba",
    ltz: "Luxembourgian",
    mlg: "Malagasy",
    msa: "Malay",
    mlq: "Malinke",
    mri: "Maori",
    MYN: "Mayan",
    hmn: "Miao",
    min: "Minankabaw",
    moh: "Mohawk",
    NAH: "Nahuatl",
    nya: "Nyanja",
    oji: "Ojibway",
    pap: "Papiamento",
    tpi: "Pidgin",
    prv: "Provencal",
    que: "Quechua",
    roh: "Rhaetic",
    rom: "Romany",
    kin: "Ruanda",
    run: "Rundi",
    smo: "Samoan",
    srd: "Sardinian",
    sna: "Shona",
    dak: "Sioux",
    SMI: "Sami",
    smj: "Lule Sami",
    sme: "Northern Sami",
    sma: "Southern Sami",
    som: "Somali",
    sot: "Sotho",
    sun: "Sundanese",
    swa: "Swahili",
    ssw: "Swazi",
    tgl: "Tagalog",
    tah: "Tahitian",
    qti: "Pirez",
    ton: "Tongan",
    tug: "Tun",
    qis: "Visayan",
    cym: "Welsh",
    WEN: "Sorbian",
    wol: "Wolof",
    xho: "Xhosa",
    zap: "Zapotec",
    zul: "Zulu",
    jpn: "Japanese",
    qcs: "Chinese (Simplified)",
    qct: "Chinese (Traditional)",
    kor: "Korean",
    tha: "Thai",
    ara: "Arabic",
    heb: "Hebrew",
    vie: "Vietnamese",
}).forEach(([k, v]) => OCR_LANGUAGES.add(new Base.Primitive(k, v)));

OCR_LANGUAGES.add(new Base.Primitive(OCR_AUTODETECT_ID, OCR_AUTODETECT_NAME));
