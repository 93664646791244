import Rating = require("Everlaw/Rating");
import RatingConflictType = require("Everlaw/RatingConflictType");
import User = require("Everlaw/User");

class RatingConflict {
    origRater: User.Id;
    origRating: Rating;
    newRater: User.Id;
    newRating: Rating;
    type: RatingConflictType;
    timeOrigRated: number;
    constructor(params: any) {
        Object.assign(this, params);
        this.origRating = Rating.byString(params.origRating);
        this.newRating = Rating.byString(params.newRating);
        this.type = RatingConflictType.byString(params.type);
    }
}

module RatingConflict {
    export const displayNames: { [id: string]: string } = {
        conflictType: "Conflict: Type",
        conflictOrigRating: "Conflict: Previous Rating",
        conflictNewRating: "Conflict: New Rating",
        conflictOrigRater: "Conflict: Previous Rater",
        conflictNewRater: "Conflict: New Rater",
        conflictTimeCreated: "Conflict: Prev Rating Time",
    };
}

export = RatingConflict;
