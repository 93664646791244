import Base = require("Everlaw/Base");
import HomepageFolder = require("Everlaw/HomepageFolder");
import Rest = require("Everlaw/Rest");

abstract class UserObject extends Base.SecuredObject {
    // Any object that extends UserObject should have a className field (EBOs do this by default).
    favorite: boolean;
    folders: HomepageFolder.Id[] = [];
    private lastActivity = 0; // for comparison purposes
    setFavorite(isFavorite: boolean, callback?: () => void, err?: Rest.Callback) {
        Rest.post("favorite.rest", {
            id: this.id,
            className: this.className,
            favorite: isFavorite,
        }).then(
            (data) => {
                if (!data) {
                    // Indicates the user did not have permission to update this object,
                    // most likely because they are impersonating another user.
                    return;
                }
                this.onFavorite(data, callback);
            },
            (e) => {
                err && err(e);
                throw e;
            },
        );
    }
    onFavorite(data, callback: () => void) {
        this.favorite = data.favorite;
        Base.publish(this);
        callback && callback();
    }
    defaultLastActivity() {
        return 0;
    }
    getLastActivity() {
        return this.lastActivity || this.defaultLastActivity();
    }
}

export { UserObject };
