import Base = require("Everlaw/Base");
import ChronCategory = require("Everlaw/Chron/ChronologyCategory");
import Arr = require("Everlaw/Core/Arr");
import Cmp = require("Everlaw/Core/Cmp");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");

class ChronologyLabel extends Base.Object {
    get className() {
        return "ChronologyLabel";
    }
    override id: ChronologyLabel.Id;
    name: string;
    category: ChronCategory;
    jsonData: any;
    description: string;
    relevance: string;
    constructor(params: any) {
        super(params);
        this._mixin(params);
        if (!Arr.contains(this.category.chronLabels, this.id)) {
            this.category.chronLabels.push(this.id);
        }
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.category = Base.get(ChronCategory, params.categoryId);
    }
    override display(includeCategory?: boolean) {
        if (includeCategory) {
            return this.category.display() + ": " + this.name;
        }
        return this.name;
    }
    isDateRange() {
        return this.category.isDateRange();
    }
    isProfile() {
        return this.category.isProfile();
    }
    getColor() {
        return this.category.getColor();
    }
    getTypeString() {
        if (this.isDateRange()) {
            return "event";
        }
        if (this.isProfile()) {
            return "profile";
        }
        return "Story label";
    }
    override compare(other: ChronologyLabel) {
        if (this.category.id === other.category.id) {
            return Cmp.str(this.name, other.name);
        }
        return this.category.compare(other.category);
    }
    _remove() {
        Arr.remove(this.category.chronLabels, this.id);
        Base.remove(this);
    }
    delete(callback?: () => void) {
        QueryDialog.create({
            prompt:
                `Are you sure you want to delete ${this.display(true)}?`
                + ` This ${this.getTypeString()} will be removed from all documents in this Story.`,
            submitText: "Delete",
            cancelText: "Cancel",
            title: `Delete ${this.getTypeString()}: ${this.display(false)}`,
            submitIsSafe: false,
            onSubmit: () => {
                Rest.post(Project.CURRENT.url("chronology/deleteChronLabel.rest"), {
                    chronLabelId: this.id,
                });
                return true;
            },
        });
    }
    rename(newName: string, success?: () => void, err?: () => void) {
        Rest.post(Project.CURRENT.url("chronology/renameLabel.rest"), {
            chronLabelId: this.id,
            name: newName,
        }).then(
            () => success && success(),
            (e) => {
                err && err();
                throw e;
            },
        );
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module ChronologyLabel {
    export type Id = number & Base.Id<"ChronologyLabel">;
    export function rename(
        chronLabel: ChronologyLabel,
        name: string,
        onSuccess?: () => void,
        onError?: () => void,
    ) {
        Rest.post(Project.CURRENT.url("chronology/renameLabel.rest"), {
            chronLabelId: chronLabel.id,
            name: name,
        }).then(
            (data) => {
                Base.set(ChronologyLabel, data);
                onSuccess && onSuccess();
            },
            (e) => {
                onError && onError();
                throw e;
            },
        );
    }
}

export = ChronologyLabel;
