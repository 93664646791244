import Base = require("Everlaw/Base");

class DocType extends Base.Object {
    get className() {
        return "DocType";
    }
    // the name of the doctype (not a number)
    override id: string;
    extensions: string[];
    displayName: string;
    constructor(params: any) {
        super(params);
        this._mixin(params);
        this.extensions.forEach((ext) => {
            DocType.byExtension[ext] = this;
        });
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    override display() {
        return this.displayName;
    }
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module DocType {
    export const byExtension: { [text: string]: DocType } = {};

    export const toIcon: { [docType: string]: string } = {
        Audio: "music",
        Binary: "binary",
        CAD: "file-cad",
        Calendar: "calendar-event",
        Chat: "message-dots",
        Compressed: "file-zip",
        CRM: "file-crm",
        Database: "database",
        Document: "file",
        Email: "mail",
        Empty: "file-other",
        GIS: "file-gis",
        HTML: "file-code",
        Image: "photo",
        Mailbox: "inbox",
        Meeting: "meeting",
        Other: "file-other",
        PDF: "file-pdf",
        Presentation: "presentation",
        Profile: "profile",
        ProjectManagement: "project-management",
        Spreadsheet: "spreadsheet",
        SupportTicket: "file-spt",
        Text: "file-text",
        Transcript: "q-a-transcript",
        Unknown: "file-other",
        Video: "video",
    };
}

export = DocType;
