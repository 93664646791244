import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import BaseSingleSelect = require("Everlaw/UI/BaseSingleSelect");
import Dom = require("Everlaw/Dom");
import FocusContainerWidget = require("Everlaw/UI/FocusContainerWidget");
import Icon = require("Everlaw/UI/Icon");
import { IconButton } from "Everlaw/UI/Button";
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import TextBox = require("Everlaw/UI/TextBox");
import UI = require("Everlaw/UI");
import Util = require("Everlaw/Util");

import eventUtil = require("dojo/_base/event");
import dojo_keys = require("dojo/keys");
import dojo_on = require("dojo/on");
import { getFocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";
import { ValidatedSubmitForm } from "Everlaw/UI/ValidatedSubmit";

interface ObjectListDiv<OBJ> extends HTMLDivElement {
    item: OBJ;
    xIcon: Icon;
    xDisabledIcon: Icon;
    readonly?: boolean;
    destroyable?: Util.Destroyable;
}

interface ObjectListDivWrapper<OBJ> extends HTMLDivElement {
    itemDiv: ObjectListDiv<OBJ>;
}

/**
 * Parent class for `ObjectList` and `ComboObjectList`.
 */
abstract class BaseObjectList<OBJ extends Base.Object>
    extends FocusContainerWidget
    implements ValidatedSubmitForm
{
    abstract constructSelector(params: BaseSingleSelect.Params<OBJ>): BaseSingleSelect<OBJ>;

    onSubmit(): void {}
    onChange(obj: OBJ, add: boolean): void {}
    _isValid(values: OBJ[]): boolean {
        return true;
    }
    isValidObj(obj: OBJ): boolean {
        return true;
    }
    onObjectClick: (obj: OBJ) => void;
    createNew(name: string, clazz: string, success: (obj: OBJ) => void): void {}
    blockMode: boolean;
    private _itemList: HTMLElement;
    selector: BaseSingleSelect<OBJ>;
    fixedHeight: number;
    private readonly?: (obj: OBJ) => string;
    newOption = false;
    newClass?: { prototype: { className: string } };
    submitOnBlur?: boolean = false;
    validateForSubmit: () => void;
    preventDisplayDuplicates?: boolean;
    duplicateResolver: (obj: OBJ) => string;
    allowCommaSeparators: boolean;
    private plusDiv: HTMLElement;
    private hasSelectedSwatch?: boolean;
    private readonly placeholder?: string;
    private readonly hidePlaceHolderWhenNonEmpty?: boolean;
    private readonly maxWidth?: string;
    private readonly removeIconName: string;
    private readonly removeIconNameForInvalid: string;
    private focusStyling: string | string[];
    protected constructor(params: BaseObjectList.Params<OBJ>) {
        super(Dom.span({ class: "object-list" }));
        if (params.onChange) {
            this.onChange = params.onChange;
        }
        if (params.isValid) {
            this._isValid = params.isValid;
        }
        if (params.isValidObj) {
            this.isValidObj = params.isValidObj;
        }
        if (params.onObjectClick) {
            this.onObjectClick = params.onObjectClick;
        }
        if (params.createNew) {
            this.createNew = params.createNew;
            this.newOption = true;
            this.newClass = params.newClass;
        }
        this.blockMode = !!params.blockMode;
        this.readonly = params.readonly;
        this.submitOnBlur = params.submitOnBlur;
        this.placeholder = params.placeholder;
        this.preventDisplayDuplicates = params.preventDisplayDuplicates;
        this.duplicateResolver = params.duplicateResolver ?? ((obj) => obj.display());
        this.allowCommaSeparators = !!params.allowCommaSeparators;
        this.hidePlaceHolderWhenNonEmpty = params.hidePlaceholderWhenNonEmpty;
        const itemListPadding = 6;
        const fixedHeightParam = params.fixedHeight;
        if (this.blockMode && fixedHeightParam !== undefined) {
            this.fixedHeight = fixedHeightParam;
        }
        const listStyle = this.blockMode
            ? { paddingBottom: itemListPadding + "px" }
            : { display: "inline-block", verticalAlign: "top" };
        this.removeIconName = params.removeIconName || "x-red";
        this.removeIconNameForInvalid = params.removeIconNameForInvalid || "x-white";
        this.hasSelectedSwatch = params.hasSelectedSwatch;
        this.maxWidth = params.maxWidth;
        this._itemList = Dom.create("div", { style: listStyle }, this.node);
        this.connect(this._itemList, "keydown", (e: Event) => {
            const event = e as KeyboardEvent;
            event.stopPropagation();
            let target = <ObjectListDiv<OBJ>>event.target;
            // The event can originate from multiple elements inside the subtree of the ObjectListDiv,
            // but we want target to refer to the ObjectListDiv node. We traverse up the DOM tree until
            // we find the ObjectListDiv node with the "object" class. If we don't find it before
            // hitting the top of the tree, don't execute the rest of the callback.
            while (!Dom.hasClass(target, "object")) {
                if (target.parentElement) {
                    target = <ObjectListDiv<OBJ>>target.parentElement;
                } else {
                    return;
                }
            }
            // The item to be focused after a given keypress is either going to be an ObjectListDiv
            // or the selector itself. Since the ObjectListDivs are contained in wrappers, we need
            // one more call to parentElement in order to point to the correct node.
            let f: ObjectListDivWrapper<OBJ> | BaseSingleSelect<OBJ> | undefined;
            if (event.keyCode === dojo_keys.LEFT_ARROW) {
                // Look left for a focusable object. If the original target was the left-most
                // object, there's nothing left to go to.
                f = <ObjectListDivWrapper<OBJ>>target.parentElement?.previousSibling;
            } else if (event.keyCode === dojo_keys.RIGHT_ARROW) {
                // Look right for a focusable object. This could be an object or the selector.
                f = <ObjectListDivWrapper<OBJ>>target.parentElement?.nextSibling || this.selector;
            } else if (event.keyCode === dojo_keys.BACKSPACE) {
                // Remove the original target and look left. If there's nothing left of the target,
                // focus the selector.
                f =
                    <ObjectListDivWrapper<OBJ>>target.parentElement?.previousSibling
                    || this.selector;
                if (!(<ObjectListDivWrapper<OBJ>>target.parentElement).itemDiv.readonly) {
                    this._remove(<ObjectListDivWrapper<OBJ>>target.parentElement);
                } else {
                    return;
                }
            } else if (event.keyCode === dojo_keys.DELETE) {
                // Remove the original target and look right. If there's nothing right of the target,
                // focus the selector.
                f = <ObjectListDivWrapper<OBJ>>target.parentElement?.nextSibling || this.selector;
                if (!(<ObjectListDivWrapper<OBJ>>target.parentElement).itemDiv.readonly) {
                    this._remove(<ObjectListDivWrapper<OBJ>>target.parentElement);
                } else {
                    return;
                }
            } else if (event.keyCode === dojo_keys.ENTER) {
                this.onSubmit();
            }
            if (f) {
                event.preventDefault(); // don't let this turn into a keypress on the input box
                if (Dom.isHidden(f)) {
                    // this.selector can be hidden if using the plus icon
                    Dom.show(f);
                    this.plusDiv && Dom.hide(this.plusDiv);
                }
                const toFocus = f instanceof BaseSingleSelect ? f : getFocusDiv(f.itemDiv);
                toFocus?.focus();
            }
        });
        const selectorParams: BaseSingleSelect.Params<OBJ> = {
            popup: params.blockMode ? undefined : "after",
            forceDirection: params.forceDirection,
            menuMaxHeight: this.blockMode
                ? this.fixedHeight - itemListPadding + "px"
                : params.menuMaxHeight,
            headers: Is.boolean(params.headers) ? params.headers : params.elements.length > 1,
            nameMap: params.nameMap || {},
            classOrder: params.classOrder,
            placeholder: params.placeholder,
            elements: params.elements,
            readOnly: params.readonly || (() => null),
            icon: params.icon || (() => null),
            iconConfig: params.iconConfig || ((e: OBJ, icon: Icon) => {}),
            handleKey: (k: number, evt: KeyboardEvent) => {
                if ((<HTMLInputElement>evt.target).selectionEnd === 0) {
                    let prev = <ObjectListDivWrapper<OBJ>>this._itemList.lastChild;
                    if (prev && k === dojo_keys.LEFT_ARROW) {
                        prev.itemDiv.focus();
                    } else if (prev && k === dojo_keys.BACKSPACE) {
                        while (prev && prev.itemDiv.readonly) {
                            prev = <ObjectListDivWrapper<OBJ>>prev.previousSibling;
                        }
                        if (prev) {
                            this._remove(prev);
                        }
                    } else if (k === dojo_keys.ENTER) {
                        this.onSubmit();
                    }
                }
                if (k === dojo_keys.ENTER) {
                    eventUtil.stop(evt); // prevent unintentional form submission
                }
                return true;
            },
            onSelect: (item, isNew, selector) => {
                if (!this.preventDisplayDuplicates || !this.isCaseInsensitiveDuplicate(item)) {
                    this.add(item);
                    selector?.unselect(item);
                }
                this.selector.clear();
            },
            onBlur: () => {
                if (this.plusDiv) {
                    Dom.show(this.plusDiv);
                    Dom.hide(this.selector);
                }
                if (
                    this.submitOnBlur
                    && this.selector.isNewHovered()
                    && !(document.activeElement && this._itemList.contains(document.activeElement))
                ) {
                    this.selector.enterKey(true);
                }
            },
            newOption: this.newOption,
            createNew: (name, clazz, success) => this.createNew(name, clazz, success),
            newClass: this.newClass,
            textBoxParams: params.textBoxParams,
            dontShowSelectIcon: true,
        };
        const prepRowElementParam = params.prepRowElement;
        if (prepRowElementParam) {
            selectorParams.prepRowElement = (obj) => {
                // Unsafe cast to make file strict-TS compliant.
                const row = prepRowElementParam(obj as OBJ);
                Dom.addClass(row.node, "dont-show-select-icon");
                return row;
            };
        }
        if (params.canAdd) {
            selectorParams.canAdd = params.canAdd;
        }
        if (params.matchStyles) {
            selectorParams.matchStyles = params.matchStyles;
        }
        if (params.display) {
            selectorParams.display = params.display;
        }
        if (params.getDisplayValues) {
            selectorParams.getDisplayValues = params.getDisplayValues;
        }
        if (params.getRowBodyTooltips) {
            selectorParams.getRowBodyTooltips = params.getRowBodyTooltips;
        }
        if (params.textBoxAriaLabel) {
            selectorParams.textBoxAriaLabel = params.textBoxAriaLabel;
        }
        if (params.maxRenderedElements) {
            selectorParams.maxRenderedElements = params.maxRenderedElements;
        }
        this.selector = this.constructSelector(selectorParams);
        if (this.blockMode) {
            Dom.style(this.selector.getNode(), { display: "block" });
        }
        Dom.place(this.selector, this.node);
        this.registerDestroyable(this.selector);

        if (params.hasButton) {
            this.plusDiv = Dom.div({ class: "plus-container" });
            const selectFunc = () => {
                Dom.hide(this.plusDiv);
                Dom.show(this.selector);
                this.selector.focus();
            };
            this.connect(this.plusDiv, Input.tap, () => selectFunc());
            Dom.place(this.plusDiv, this.node);
            new IconButton({
                iconClass: params.smallButton ? "circle-plus-blue-20" : "circle-plus-blue",
                ariaLabel: "Add",
                parent: this.plusDiv,
            });
            Dom.hide(this.selector);
            const focusDiv = makeFocusable(this.plusDiv, "focus-with-space-style");
            this.registerDestroyable(focusDiv);
            this.registerDestroyable(
                Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => selectFunc()),
            );
        }
        this.focusStyling = params.focusStyling || "focus-with-space-style";
    }

    // If you construct a widget with pre-selected items, you'll need to call this method once after
    // the widget is shown so that it can initially adjust its height. (The position of the itemList
    // must be set before the height of the selector can be computed)
    adjustHeight(): void {
        if (this.blockMode) {
            this.selector.updateMenuMaxHeight(
                this.fixedHeight - Dom.position(this._itemList, false).h - 2 + "px",
            );
        }
    }

    // Add an element to the selected list. Returns the wrapper for the newly added div.
    add(item: OBJ, silent = false): ObjectListDivWrapper<OBJ> {
        let wrapperClazz = "object-wrapper";
        let swatch = null;
        if (this.hasSelectedSwatch) {
            wrapperClazz += "--flex";
            swatch = Dom.div({ class: "object__left-swatch" });
        }
        const itemWrapper = <ObjectListDivWrapper<OBJ>>(
            Dom.create("div", { class: wrapperClazz, content: swatch }, this._itemList)
        );
        const itemDiv = <ObjectListDiv<OBJ>>Dom.create(
            "div",
            {
                content: this.selector.display(item),
                class: "object",
                tabindex: -1,
            },
            itemWrapper,
        );
        if (this.maxWidth) {
            Dom.style(itemDiv, "maxWidth", this.maxWidth);
        }
        const valid = this.isValidObj(item);
        if (!valid) {
            Dom.addClass(itemDiv, "bold invalid-object");
        }
        itemWrapper.itemDiv = itemDiv;
        itemDiv.item = item;
        const iconClass = valid ? this.removeIconName : this.removeIconNameForInvalid;
        itemDiv.xIcon = new Icon.ActionIcon(iconClass, {
            tooltip: "Remove this entry",
            onClick: () => {
                // Focusing here avoids creating an extra blur event.
                const elt = <HTMLElement>itemDiv.nextSibling || this.selector;
                if (Dom.isHidden(elt)) {
                    // Selector can be hidden if plus icon is used.
                    Dom.show(elt);
                    this.plusDiv && Dom.hide(this.plusDiv);
                }
                elt.focus();
                this._remove(itemWrapper);
            },
            parent: itemDiv,
            makeFocusable: true,
            focusStyling: "focus-text-style",
        });
        // Even if the objects in the list don't have click-functionality, we want them
        // to be focusable to support other keyboard shortcuts.
        const focusDiv = makeFocusable(itemDiv, this.focusStyling, "first");
        itemDiv.readonly = this.readonly && !!this.readonly(item);
        if (this.onObjectClick) {
            const onClickFunc = () => {
                Dom.isHidden(itemDiv.xDisabledIcon) && this.onObjectClick(item);
            };
            itemDiv.destroyable = dojo_on(itemDiv, Input.tap, () => onClickFunc());
            Dom.addClass(itemDiv, "action");
            focusDiv.registerDestroyable(
                Input.fireCallbackOnKey(focusDiv.node, [Input.ENTER], () => onClickFunc()),
            );
        }
        itemDiv.xDisabledIcon = new Icon("x disabled", {
            tooltip: "You cannot remove this entry",
            parent: itemDiv,
        });
        if (itemDiv.readonly) {
            Dom.hide(itemDiv.xIcon);
        } else {
            Dom.hide(itemDiv.xDisabledIcon);
        }
        this.selector.hide(item);
        if (!silent) {
            this._onChange(item, true);
        }
        return itemWrapper;
    }

    // Remove an element from the selected list.
    remove(item: OBJ, silent = false): void {
        let toRemove: ObjectListDivWrapper<OBJ> | undefined;
        for (const child of this._itemList.children) {
            const n = child as unknown as ObjectListDivWrapper<OBJ>;
            if (n.itemDiv.item === item) {
                toRemove = n;
            }
        }
        toRemove && this._remove(toRemove, silent);
    }

    // Enable/Disable an element.
    setElemDisabled(item: OBJ, disabled: boolean): void {
        if (disabled) {
            // Make sure a disabled item is first removed from the selected list
            this.remove(item, true);
            this.selector.hide(item);
        } else if (this.getValues().indexOf(item) < 0) {
            // Only show an enabled item if it's not already selected.
            this.selector.show(item);
        }
        this._onChange(item, false);
    }

    // Set the readonly status of a selected object.
    setSelectedReadOnly(item: OBJ, readonly = true): void {
        for (const child of this._itemList.children) {
            const n = child as unknown as ObjectListDivWrapper<OBJ>;
            if (n.itemDiv.item === item) {
                n.itemDiv.readonly = readonly;
                Dom.show(n.itemDiv.xIcon, !readonly);
                Dom.show(n.itemDiv.xDisabledIcon, readonly);
            }
        }
    }

    private _remove(wrapper: ObjectListDivWrapper<OBJ>, silent = false): void {
        const item = wrapper.itemDiv.item;
        this.selector.show(item);
        Util.destroy([
            wrapper.itemDiv.xIcon,
            wrapper.itemDiv.xDisabledIcon,
            wrapper.itemDiv.destroyable || [],
        ]);
        Dom.destroy(wrapper);
        if (!silent) {
            this._onChange(item, false);
        }
    }

    getValues(): OBJ[] {
        return [...this._itemList.children].map(
            (n) => (n as unknown as ObjectListDivWrapper<OBJ>).itemDiv.item,
        );
    }

    override focus(): void {
        if (this.plusDiv) {
            Dom.hide(this.plusDiv);
            Dom.show(this.selector);
        }
        this.selector.focus();
    }

    override destroy(): void {
        while (this._itemList.firstChild) {
            this._remove(<ObjectListDivWrapper<OBJ>>this._itemList.firstChild, true);
        }
        super.destroy();
    }

    emptySelected(silent = true): void {
        while (this._itemList.firstChild) {
            this._remove(<ObjectListDivWrapper<OBJ>>this._itemList.firstChild, silent);
        }
    }

    emptySelector(silent = true): void {
        this.selector.removeMultiple(this.selector.getElementsAsArray(), silent);
    }

    makeEmpty(silent = true): void {
        this.emptySelected(silent);
        this.emptySelector(silent);
    }

    clear(): void {
        this.selector.clear();
    }

    addMultipleToSelector(objs: OBJ[]): void {
        this.selector.addMultiple(objs);
    }

    addToSelector(obj: OBJ): void {
        this.selector.add(obj);
    }

    protected _onChange(obj: OBJ, added: boolean): void {
        this.selector.filter(this.selector.getTextValue());
        this.adjustHeight();
        if (this.hidePlaceHolderWhenNonEmpty) {
            this.selector.tb.setPlaceholder(
                // Unsafe cast to make file strict-TS compliant.
                this.getValues().length ? "" : (this.placeholder as string),
            );
        }
        this.validateForSubmit && this.validateForSubmit();
        this.onChange(obj, added);
    }

    setDisabled(disabled: boolean): void {
        for (const child of this._itemList.children) {
            const oneItem = child as unknown as ObjectListDivWrapper<OBJ>;
            // opaqueness and hover styling
            UI.toggleDisabled(oneItem.itemDiv, disabled);
            // icon clickability
            UI.toggleDisabled(oneItem.itemDiv.xIcon, disabled);
            UI.toggleDisabled(oneItem.itemDiv.xDisabledIcon, disabled);
        }
        this.selector.setDisabled(disabled);
    }

    setTextBoxAriaLabel(ariaLabel: string): void {
        this.selector.setTextBoxAriaLabel(ariaLabel);
    }

    addToSubmit(): void {}

    isValid(): boolean {
        return this._isValid(this.getValues());
    }

    subscribeToChanges(subscription: () => void): void {
        this.validateForSubmit = subscription;
    }

    isCaseInsensitiveDuplicate(item: OBJ | string): boolean {
        const display = Is.string(item) ? item : this.duplicateResolver(item);
        return this.getValues().some(
            (value) => this.duplicateResolver(value).toLowerCase() === display.toLowerCase(),
        );
    }
}

module BaseObjectList {
    export interface Params<OBJ extends Base.Object> extends UI.WidgetWithTextBoxParams {
        // A list of lists, broken down by EBO type
        elements: OBJ[][];
        // can you add a new element with this name?
        canAdd?: (name: string) => boolean;
        // Create your new element with the given name.
        // Should call callback with the EBO as the sole argument once it is completed.
        createNew?: (name: string, clazz: string, success: (obj: OBJ) => void) => void;
        newClass?: { prototype: { className: string } };
        // If some of the object types require special descriptions, put them here
        nameMap?: { [className: string]: string };
        classOrder?: Base.Class<OBJ>[];
        placeholder?: string;
        hidePlaceholderWhenNonEmpty?: boolean;
        // Max height of the menu
        menuMaxHeight?: string;
        // Whether this list should use a blue plus icon button to show the select textbox.
        hasButton?: boolean;
        // Whether this list should use a small blue plus icon button.
        smallButton?: boolean;
        // Called after an object is selected or removed.
        onChange?: (obj: OBJ, added: boolean) => void;
        // Called when an object's div is clicked on.
        onObjectClick?: (obj: OBJ) => void;
        prepRowElement?: (obj: OBJ) => BaseSelect.Row;
        // Whether to call submit when the selector is blurred.
        submitOnBlur?: boolean;
        blockMode?: boolean;
        fixedHeight?: number;
        // If provided, the max allowed width for each ObjectListDiv.
        maxWidth?: string;
        headers?: boolean;
        // If blockMode is falsy, whether to force the popup to be in the specified direction ("after").
        forceDirection?: boolean;
        readonly?: (obj: OBJ) => string;
        // A function that returns the icon class to display for the provided element.
        // This does NOT override the readOnly icon.
        icon?: (elem: OBJ) => string;
        // Config the icon displayed for the provided element.
        iconConfig?: (elem: OBJ, icon: Icon) => void;
        // See BaseSelect.Params
        display?: (objOrName: OBJ | string) => string;
        // See BaseSelect.Params.
        getDisplayValues?: (obj: OBJ) => Dom.Content[];
        // See BaseSelect.Params.
        matchStyles?: () => string[];
        // See BaseSelect.Params.
        getRowBodyTooltips?: (obj: OBJ) => Dom.Content;
        // See BaseSelect.Params.
        textBoxParams?: TextBox.Params;
        // Custom remove icon. Default is `x-red`.
        removeIconName?: string;
        // Icon class to use for displaying invalid entries. Defaults to "x-white".
        removeIconNameForInvalid?: string;
        // Whether the selected elements have a left swatch
        hasSelectedSwatch?: boolean;
        // Whether the current list of values is valid. Used for form validation, e.g. with a
        // ValidatedSubmit.
        isValid?: (values: OBJ[]) => boolean;
        // Whether an individual element is valid. If invalid, styling is applied which indicates it
        // as such.
        isValidObj?: (obj: OBJ) => boolean;
        // The focus style class to use when focused with a keyboard.
        focusStyling?: string | string[];
        // Whether or not to prevent display-based duplicates
        preventDisplayDuplicates?: boolean;
        // The stringifier used to determine if two things are dupes. Defaults to obj#display
        duplicateResolver?: (obj: OBJ) => string;
        // Whether or not to treat a comma separated input as a list of inputs
        allowCommaSeparators?: boolean;
        // Maximum number of rendered elements from the select.
        maxRenderedElements?: number;
    }
}

export = BaseObjectList;
