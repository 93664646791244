import ArmDetails = require("Everlaw/Model/Upload/Util/ArmDetails");
import MissingFiles = require("Everlaw/UI/Upload/Util/MissingFiles");
import NameStatus = require("Everlaw/UI/Upload/Metadata/NameStatus");
import * as Bates from "Everlaw/Bates";
import { BatesPrefixType, FileAndBates } from "Everlaw/Model/Upload/Util/PdfnlfParsing";
import { PrefixParsingLevel } from "Everlaw/PrefixParsingLevel";
import type Project = require("Everlaw/Project");
import {
    UploadFile,
    UploadFileErrorType,
    UploadFileLimits,
} from "Everlaw/UI/Upload/Util/PdfnlfUtils";
import type Type = require("Everlaw/Type");

/**
 * The structural type of an Upload's state. See UploadState.java.
 */
interface UploadState {
    rootFolder: string;
    loadfileName: string;
    loadfilePath: string;
    optfileName: string;
    optfilePath: string;
    hasOpt: boolean;
    documentCount: number;
    armDetails: ArmDetails;
    columns: UploadState.ColumnInfo[];
    loadfileError: boolean;
    prefixParsingLevel: PrefixParsingLevel;
    existingBatesPrefixes: string[];
    batesPrefixes: string[];
    hashReplacementPrefix: string;
    loadfileSplitSummary: UploadState.LoadfileSplitSummary;
    columnDefinition: UploadState.ColumnDefinition;
    countOfPageNumberUpdates: number;
    countOfPageNameUpdates: number;
    countOfEndBatesDecreases: number;
    countOfEndBatesIncreases: number;
    folders: UploadState.Folders;
    missingFiles: MissingFiles.Summary;
    exceedSize: UploadState.ExceedSizeSummary;
    parseErrorLineCount: number;
    interpretationErrorLineCount: number;
    interpretationErrorRowCount: number;
    optRequiredPageCountConflict: boolean;
    optRequiredUnknownPageRange: boolean;
    countOfSkippedDocuments: number;
    projectIds: number[];
    newBinderNames: string[];
    customMetadataFieldsToAdd: { [field: string]: string | Type.DateTime };
    metadataMutations: string;
    workProductMutations: string;
    originatingProjectId: Project.Id;
    /**
     * Indicates and gives information about a previous automatic abort because of a websocket
     * error.
     */
    wsRetryTracker: UploadState.WebSocketRetryTracker;
    // A MetadataState object's JSON.
    metadata: { definition: any; analyses: any };
    wipedCols: any[];
    nameRestrictions: NameStatus.NameRestrictions;
    allowOverlappingBates: boolean;
    internalOverlapRowCount: number;
    externalOverlapRowCount: number;
    uploadFileList: UploadState.UploadFileList;
    batesGroupList: UploadState.BatesPrefixList;
    pdfnlfOverlayErrors: UploadState.UploadFileError[];
    selectedProjectNames: string[];
    promotionReasons: string[];
}

/* TODO Refactor this to remove module namespace */
/* eslint-disable-next-line @typescript-eslint/no-namespace */
module UploadState {
    export function reviver(isPdfnlf: boolean, key: string, value?: unknown): unknown {
        // This reviver function is needed to deserialize key properties in PDFNLF uploads
        if (isPdfnlf && value != null && typeof value === "object") {
            if (key === "filenameToUploadFile") {
                // Deserializes from a Java Map<String, UploadFile>
                return new Map<string, UploadFile>(
                    Object.entries(value as Record<string, UploadFile>),
                );
            } else if (key === "groupMap") {
                // Deserializes from a Java Map<String, PrefixGroup>
                return new Map<string, PrefixGroup>(
                    Object.entries(value as Record<string, PrefixGroup>),
                );
            } else if (key === "bates") {
                const castedValue = value as Bates;
                return {
                    prefix: castedValue.prefix,
                    // We have to do this odd cast here since at this point value.number is actually
                    // a string, but the compiler thinks that it's a Bates.Number.
                    number: Bates.Number.fromString(castedValue.number as unknown as string),
                    suffix: castedValue.suffix,
                    page: castedValue.page
                        ? Bates.Page.fromJson(castedValue.page as Bates.PageJson)
                        : null,
                } as Bates;
            }
        }
        return value;
    }

    export function replacer(isPdfnlf: boolean, key: string, value?: unknown): unknown {
        // This replacer function is needed to serialize key properties in PDFNLF uploads
        if (isPdfnlf && value != null) {
            if (value instanceof Map && key === "filenameToUploadFile") {
                // TS maps aren't serializable by default, so we convert them to straight objects
                return Object.fromEntries(value as Map<string, UploadFile>);
            } else if (value instanceof Map && key === "groupMap") {
                // Same map -> object conversion as above
                return Object.fromEntries(value as Map<string, PrefixGroup>);
            } else if (key === "bates") {
                const castedValue = value as Bates;
                return {
                    prefix: castedValue.prefix,
                    number: castedValue.number.toJSON(),
                    suffix: castedValue.suffix,
                    page: castedValue.page?.toJSON() ?? null,
                };
            }
        }
        return value;
    }

    export enum ArmEverlawFieldType {
        METADATA = "Metadata",
        CATEGORY = "Code categories",
    }

    export interface ArmField {
        displayName: string;
        columnName: string;
        artifactId: number;
        fieldTypeId: number;
        artifactTypeId: number;
        everlawType: ArmEverlawFieldType;
        isIgnored: boolean;
        examples: string[];
    }

    export interface ArmTableInfo {
        tableName: string;
        schemaName: string;
        columns: ArmColumnInfo[];
    }

    export interface ArmTableChoice {
        tableName: string[];
        schemaName: string;
    }

    export interface ArmDocumentTableChoice extends ArmTableChoice {
        controlNumber: string;
        extractedText: string;
        artifactId: string;
    }

    export interface ArmProducedDocumentTableChoice extends ArmTableChoice {
        artifactId: string;
        beginBates: string;
        endBates: string;
        docArtifactId: string;
        prodArtifactId: string;
    }

    export interface ArmFileTableChoice extends ArmTableChoice {
        guid: string;
        filename: string;
        type: string;
        order: string;
        docArtifactId: string;
        fileId: string;
    }

    export interface ArmProducedFileTableChoice extends ArmTableChoice {
        prodFileId: string;
        batesNumber: string;
        prodArtifactId: string;
        docArtifactId: string;
    }

    export interface ArmFieldTableChoice extends ArmTableChoice {
        artifactId: string;
        artifactTypeId: string;
        fieldTypeId: string;
        artifactViewFieldId: string;
        codeTypeId: string;
        displayName: string;
    }

    export interface ArmCodeTableChoice extends ArmTableChoice {
        artifactId: string;
        codeTypeId: string;
        name: string;
    }

    export interface ArmArtifactViewFieldTableChoice extends ArmTableChoice {
        artifactViewFieldId: string;
        artifactTypeId: string;
        columnName: string;
        headerName: string;
    }

    export interface ArmArtifactTableChoice extends ArmTableChoice {
        artifactId: string;
        artifactTypeId: string;
        textIdentifier: string;
    }

    export interface ArmArtifactTypeTableChoice extends ArmTableChoice {
        artifactTypeId: string;
        artifactType: string;
    }

    export interface ArmZCodeArtifactTableChoice extends ArmTableChoice {
        associatedArtifactId: string;
        codeArtifactId: string;
    }

    export interface ArmColumnInfo {
        columnName: string;
        examples: string[];
    }

    export interface ColumnInfo {
        name: string;
        samples: string[];
        invalidBates: string;
        invalidBeginBates: string;
        invalidPageCount: string;
        prefixes: string[];
        numHashPrefixes: number;
        hashPrefixSamples: HashPrefixSamples;
    }

    export interface ColumnDefinition {
        beginBates: number;
        endBates: number;
        pageCount: number;
        textPath: number;
        nativePath: number;
        ignored: boolean[];
        wiped: boolean[];
    }

    export interface Folders {
        images: string[];
        text: string[];
        natives: string[];
        armFolders: string[];
    }

    export interface WebSocketRetryTracker {
        retriesSoFar: number;
        // how many times we retried without progress since last time we made progress
        retriesSinceProgress: number;
        nextRetryTimestamp: number;
    }

    /**
     * Info about new vs overlay rows. When using these numbers, remember to subtract the values
     * in ExceedSizeSummary if appropriate!
     */
    export interface LoadfileSplitSummary {
        numNew: number;
        numOverlay: number;
    }

    export interface HashPrefixSamples {
        rowNumbers: number[];
        batesNumbers: string[];
    }

    /**
     * Info about how many documents exceed the size limit.
     */
    export interface ExceedSizeSummary {
        numNew: number;
        numOverlay: number;
    }

    export interface UploadFileList {
        errors: UploadFileErrorGroup;
        filenameToUploadFile: Map<string, UploadFile>;
        processingCancelled: boolean;
        fileEntriesAdded: number;
        filesCompleted: number;
        fileSizeCompleted: number;
        scanFilesCalled: boolean;
    }

    export interface UploadFileErrorGroup {
        fileLimits: UploadFileLimits;
        allErrors: UploadFileError[];
        fatalError: UploadFileError;
    }

    export interface UploadFileError {
        uploadErrorType: UploadFileErrorType;
        filename: string;
        isFatal: boolean;
        batesStr: string;
        // Only needs to be populated if uploadErrorType is FILE_TOO_BIG
        fileSize?: number;
    }

    export interface BatesPrefixList {
        // Every possible prefix group generated at creation, and only updated if user adds prefixes
        //  There are many duplicate files here, since they are in every group they might be in.
        allPrefixGroups: PrefixGroupMap;
        // Current groups with files assigned, each file only appears once in this group.
        curPrefixGroups: PrefixGroupMap;
    }

    export interface PrefixGroupMap {
        groupMap: Map<string, PrefixGroup>;
        needsSorting: boolean;
        sortedGroups: PrefixGroup[];
    }

    export interface PrefixGroup {
        enabled: boolean;
        normalized: string;
        files: FileAndBates[];
        prefix: string;
        pType: BatesPrefixType;
    }
}

export = UploadState;
