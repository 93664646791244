import Base = require("Everlaw/Base");
import { EverColor } from "design-system";
import { Color } from "Everlaw/ColorUtil";

class ConflictType extends Base.Object {
    get className() {
        return "RatingConflictType";
    }
    override id: string;
    constructor(
        id: string,
        public name: string,
        public color: string,
    ) {
        super({ id });
    }
    override display() {
        return this.name;
    }
    getColor() {
        return Color.fromHexString(this.color);
    }
}

module ConflictType {
    export function byString(id: string) {
        return Base.get(ConflictType, id);
    }

    export const adminAdmin = new ConflictType("adminAdmin", "Admin-Admin", EverColor.EVERBLUE_60);
    export const adminReviewer = new ConflictType(
        "adminReviewer",
        "Admin-Reviewer",
        EverColor.EGGPLANT,
    );
    export const reviewerReviewer = new ConflictType(
        "reviewerReviewer",
        "Reviewer-Reviewer",
        EverColor.RED_40,
    );

    export const all = [adminAdmin, adminReviewer, reviewerReviewer];
    all.forEach(Base.add);
}

export = ConflictType;
