import { TypeFormat } from "Everlaw/UI/Upload/Metadata/TypeFormat";

/**
 * Corresponds to CombinationAnalysis.java.
 */
class CombinationAnalysis {
    typeAnalysisIds: string[];
    headers: string[];
    compatibleNames: boolean;
    sampleInputs: string[][];
    sampleResults: string[];
    numValues = 0;
    numCombined = 0;
    numDistinct = 0;
    rejected = false;
    constructor(params: any) {
        Object.assign(this, params);
        this.typeAnalysisIds = params.typeAnalysisIds.map(TypeFormat.idFromJson);
    }
    markRejected() {
        // This decision isn't persisted and will only be remembered for this page load.
        this.rejected = true;
    }
    otherId(taId: string) {
        if (this.typeAnalysisIds[0] === taId) {
            return this.typeAnalysisIds[1];
        }
        return this.typeAnalysisIds[0];
    }
    /**
     * This should always match CombinationAnalysis.java#id.
     */
    id(): string {
        // Use built-in sort to ascii-sort as Java does.
        return JSON.stringify(this.typeAnalysisIds.sort());
    }
}

export { CombinationAnalysis };
